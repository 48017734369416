// import LRU from 'lru-cache';
import { SCHEDULE_STATUS } from 'constants/schedule';
import {
  EExcelType,
  STAFF_TYPE_ENUM,
  COURSE_TAGS_FILTER,
} from 'constants/teacher.constants';
import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import { ITeacher } from '@type/teacher.type';

export interface IScheduleParams {
  startTime: number;
  endTime: number;
}
export interface ICreateScheduleParams {
  startTime: number;
  endTime: number;
  userId: string;
}
export interface IDeleteScheduleParams {
  remarks: string;
}

export interface IBitrixParams {
  id: string;
}

interface IBasicTeacher {
  id: string;
  fullName: string;
  oneClubId: string;
  scheduleStatus: SCHEDULE_STATUS;
}

interface ITeacherAvailableBasic {
  teachers: IBasicTeacher[];
  total: number;
}

export interface IGetAvailableTeacherParams {
  courseTags?: string[];
  startAt?: number;
  endAt?: number;
  skip?: number;
  limit?: number;
  keyword?: string;
  status?: string[];
  courseTagsFilter?: COURSE_TAGS_FILTER;
}

export interface ITutorials {
  userId: string;
  name: string;
  education: string;
  subject: string;
  project: string;
  projectId: string;
  isForced: boolean;
  startTime: number;
  endTime: number;
  remarks: string;
  year: string;
  usagePeriodStatus: string;
  courseName: string;
  tutorialName: string;
  crmId: string;
  bookId: string;
  advisor: string;
  advisorId: string;
  audiovisualNumber: string;
  classType: string;
}

interface ITeacherAvailableData {
  teachers: ITeacher[];
  total: number;
}

interface IExportTeacherList {
  data: BlobPart;
}

export interface ITeacherListParams {
  skip?: number;
  limit?: number;
  courseSubjects?: string[];
  courseTags?: string[];
  fullName?: string;
  keyword?: string;
  styleTags?: string[];
  level?: number[];
  star?: number[];
  priority?: string[];
  staffType?: STAFF_TYPE_ENUM;
  isAvailable?: boolean;
  startAt?: number;
  endAt?: number;
  repeatedWeek?: number;
  repeatedWeekdays?: number[];
  durationsStartAt?: number[];
  durationsEndAt?: number[];
  status?: string[];
  courseTagsFilter?: string;
}
export interface IExportTeacherParams extends ITeacherListParams {
  excelType: EExcelType;
}
interface IExportScheduleParams {
  year: number;
  month: number;
}

export interface ITeacherListResponse {
  total: number;
  teachers: ITeacher[];
}

export default (axios: AxiosInstance, teacher: string) => ({
  /**
   * 匯出老師列表
   */
  exportTeacherList(
    data: IExportTeacherParams,
  ): TApiResponse<IExportTeacherList> {
    return axios.get(`${teacher}/mms/teachers/export`, { params: data });
  },
  /**
   * 取得老師列表
   */
  getTeacherList(data: ITeacherListParams): TApiResponse<ITeacherListResponse> {
    return axios.get(`${teacher}/mms/teachers`, { params: data });
  },
  /**
   * 新增老師
   */
  createTeacher(data: any): any {
    return axios.post(`${teacher}/mms/teachers`, data);
  },
  /**
   * 取得單個老師
   */
  getTeacherById(id: string): TApiResponse<ITeacher> {
    return axios.get(`${teacher}/mms/teachers/${id}`);
  },
  /**
   * 編輯老師
   */
  updateTeacherById(id: string, data: any) {
    return axios.patch(`${teacher}/mms/teachers/${id}`, data);
  },
  /**
   * 上傳圖片
   */
  uploadAvatar(oneClubId: string, data: any): any {
    return axios.post(`${teacher}/mms/teachers/avatar/${oneClubId}`, data);
  },
  /**
   * 開課選老師
   */
  getTeacherAvailable(data: any): TApiResponse<ITeacherAvailableData> {
    return axios.get(`${teacher}/mms/teachers/available`, { params: data });
  },
  /**
   * 開課選老師輕量版
   */
  getTeacherAvailableBasic(
    data: IGetAvailableTeacherParams,
  ): TApiResponse<ITeacherAvailableBasic> {
    return axios.get(`${teacher}/mms/teachers/available/basic`, {
      params: data,
    });
  },
  /**
   * 檢視oneClubID 是否存在
   */
  checkOneClubIDinTeacher(oneClubId: string): any {
    return axios.get(`${teacher}/mms/teachers/oneclub-id-exist/${oneClubId}`);
  },
  /**
   * 新增班表
   */
  postSchedules(data: any, ownerId: string): any {
    return axios.post(`${teacher}/mms/schedules/${ownerId}`, data);
  },
  /**
   * 新增重複班表
   */
  postDurationSchedules(data: any, ownerId: string): any {
    return axios.post(`${teacher}/mms/schedules/durations/${ownerId}`, data);
  },
  /**
   * 取得班表
   */
  getSchedules(data: any): any {
    return axios.get(`${teacher}/mms/schedules`, { params: data });
  },
  /**
   * 編輯班表
   */
  putSchedules(id: string, data: any): any {
    return axios.put(`${teacher}/mms/schedules/${id}`, data);
  },
  /**
   * 刪除班表
   */
  deleteSchedules(ownerId: string, data: any): any {
    return axios.post(
      `${teacher}/mms/schedules/duration-delete/${ownerId}`,
      data,
    );
  },
  /**
   * 取得可預約班表
   */
  getAvailableSchedules(data: any): any {
    return axios.get(`${teacher}/mms/schedules/available-duration`, {
      params: data,
    });
  },
  /**
   * 取得可預約班表 v2
   */
  getAvailableSchedulesV2(data: any): any {
    return axios.get(`${teacher}/mms/schedules/available-duration-v2`, {
      params: data,
    });
  },
  /**
   * 取得可預約班表 v3
   */
  getAvailableSchedulesV3(data: any): any {
    return axios.get(`${teacher}/mms/schedules/available-duration-v3`, {
      params: data,
    });
  },
  /**
   * export schedule peak hour
   */
  exportSchedulePeakHourExcel(
    data: IExportScheduleParams,
  ): TApiResponse<IExportTeacherList> {
    return axios.get(
      `${teacher}/mms/schedules/exportPeakHour?year=${data.year}&month=${data.month}`,
    );
  },
});
