// TODO
// import LRU from 'lru-cache';
import { AxiosInstance } from 'axios';
import { removeUndefinedFromObject } from 'utils/common';
import { IPaginationParams, TApiResponse } from '@type/common';
import {
  ICourseTag,
  IStyleTag,
  ICourseTagProperties,
  ISeriesTag,
  IResourceTag,
  IExamTag,
  IHomeworkTag,
  ISeriesTagPayload,
  TResourceTagParams,
  TExamTagParams,
  THomeworkTagParams,
  TSeriesTagParams,
} from '@type/tag.type';
export interface ICourseTagParams extends IPaginationParams {
  keywords?: string[];
  applicable?: string;
  courseType?: string;
  education?: string;
  subject?: string;
  isDeleted?: boolean;
}

export interface IStyleTagParams {
  skip?: number;
  limit?: number;
  keywords?: string[];
}

interface IResourceTagParams {
  code: string;
  name: string;
  description?: string;
  order?: number;
  chapter?: string;
  lesson?: string;
  bookId?: string;
  seriesCode: string;
  isDeleted: boolean;
}

interface UpdateHomeworkTagParams {
  name: string;
  order: number;
  chapter: string;
  filename: string[];
  seriesCode: string;
  isDeleted: boolean;
}

interface UpdateExamTagParams {
  name: string;
  order: number;
  chapter: string;
  examId: string;
  seriesCode: string;
  isDeleted: boolean;
}

export interface ITagResponseStructure<T> {
  tags: T[];
  total: number;
}

export default (axios: AxiosInstance, tag: string) => ({
  /**
   * 取得 課程 tag
   */
  getCourseTag(
    data?: ICourseTagParams,
  ): TApiResponse<ITagResponseStructure<ICourseTag>> {
    return axios.get(
      `${tag}/tag/course`,
      data && { params: removeUndefinedFromObject(data) },
    );
  },

  /**
   * 新增 叢書 tag
   */
  createSeriesTag(data: ISeriesTagPayload): TApiResponse<any> {
    return axios.post(`${tag}/tag/series`, data);
  },
  /**
   * 取得 叢書 tag
   */
  getSeriesTag(
    data: TSeriesTagParams,
  ): TApiResponse<ITagResponseStructure<ISeriesTag>> {
    return axios.get(`${tag}/tag/series`, { params: data });
  },
  /**
   * 修改 叢書 tag
   */
  updateSeriesTag(id: string, data: ISeriesTagPayload): TApiResponse<any> {
    return axios.put(`${tag}/tag/series/${id}`, data);
  },
  /**
   * 新增 叢書教材 tag
   */
  createResourceTag(data: IResourceTagParams): any {
    return axios.post(`${tag}/tag/resource`, data);
  },
  /**
   * 取得 叢書教材 tag
   */
  getResourceTag(
    data: TResourceTagParams,
  ): TApiResponse<ITagResponseStructure<IResourceTag>> {
    return axios.get(`${tag}/tag/resource`, { params: data });
  },
  /**
   * 修改 叢書教材 tag
   */
  updateResourceTag(id: string, data: IResourceTagParams): any {
    return axios.put(`${tag}/tag/resource/${id}`, data);
  },
  /**
   * 取得 tag 資料 by code
   */
  getTagByCode(type: string, code: string): any {
    return axios.get(`${tag}/tag/${type}/${code}`);
  },
  /**
   * 取得 作業 tag
   */
  getHomeworkTag(
    data: THomeworkTagParams,
  ): TApiResponse<ITagResponseStructure<IHomeworkTag>> {
    return axios.get(`${tag}/tag/homework`, { params: data });
  },
  /**
   * 修改 作業 tag
   */
  updateHomeworkTag(
    id: string,
    data: UpdateHomeworkTagParams,
  ): TApiResponse<any> {
    return axios.put(`${tag}/tag/homework/${id}`, data);
  },
  /**
   * 取得 測驗 tag
   */
  getExamTag(
    data: TExamTagParams,
  ): TApiResponse<ITagResponseStructure<IExamTag>> {
    return axios.get(`${tag}/tag/exam`, { params: data });
  },
  /**
   * 修改 測驗 tag
   */
  updateExamTag(id: string, data: UpdateExamTagParams): TApiResponse<any> {
    return axios.put(`${tag}/tag/exam/${id}`, data);
  },
  /**
   * 上傳 tag
   */
  async uploadCourseTag(type: string, file: File): Promise<any> {
    const form = new FormData();
    form.append('file', file);
    return axios.post(`${tag}/tag/import/${type}`, form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
  /**
   * 匯出 tag
   */
  async exportCourseTag(type: string): TApiResponse<any> {
    return axios.get(`${tag}/tag/export/${type}`);
  },
  /**
   * 刪除 tag (封存)
   */
  deleteTag(type: string, id: string): TApiResponse<any> {
    return axios.delete(`${tag}/tag/${type}/${id}`);
  },
  /**
   * 取得教學風格 tag
   */
  getStyleTag(
    data?: IStyleTagParams,
  ): TApiResponse<ITagResponseStructure<IStyleTag>> {
    return axios.get(`${tag}/tag/style`, { params: data });
  },
  /**
   * 取得篩選 tag
   */
  getTagMeta(): TApiResponse<ICourseTagProperties[]> {
    return axios.get(`${tag}/tag/meta`);
  },
  /**
   * 重新排序 教材/測驗/作業
   */
  sortTag(
    type: string,
    data: {
      tags: string[];
      seriesCode: string;
    },
  ): TApiResponse<any> {
    return axios.patch(`${tag}/tag/${type}/sort`, data);
  },
  /**
   * 批次下架
   */
  batchTakeOffTag(
    type: string,
    data: {
      tags: string[];
      isDeleted: boolean;
    },
  ): TApiResponse<any> {
    return axios.post(`${tag}/tag/${type}/available`, data);
  },
});
