import { useState } from 'react';
import '@sendbird/uikit-react/dist/index.css';
import { Mui } from '@onedesign/ui';
import { useSendbirdService } from 'services/SendbirdService';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { useLoginService } from 'services/LoginService';
import { ChannelListProvider } from '@sendbird/uikit-react/ChannelList/context';
// import { sendbirdTab } from '../../../../@type/sendbird';
import ChannelListUI from './ChannelListUI';

const { Box } = Mui;

const SendbirdTicketChannel = () => {
  const [currentChannelSelected, setCurrentChannelSelected] =
    useState<GroupChannel>();
  const { loginUser } = useLoginService();
  //   const channelTypes: sendbirdTab[] = loginUser?.channelTypes || [];
  const { sendbirdAccessToken, myColorSet } = useSendbirdService();

  //   const channelTypesId = useMemo(() => {
  //     return channelTypes?.map(item => item.id);
  //   }, [channelTypes]);

  if (!loginUser?.staffNumber || !sendbirdAccessToken) return null;

  return (
    <Box
      sx={{
        pl: '32px',
        pr: '32px',
        pb: '120px',
        pt: '32px',
      }}
    >
      <SendbirdProvider
        appId={import.meta.env.VITE_SENDBIRD_APP_ID}
        userId={loginUser?.staffNumber}
        colorSet={myColorSet}
        accessToken={sendbirdAccessToken}
      >
        <Box
          sx={{
            display: 'flex',
            height: '100%',
          }}
        >
          <ChannelListProvider
            queries={{
              channelListQuery: {
                includeEmpty: true,
                customTypesFilter: [import.meta.env.VITE_PROGRESSING_TICKET_ID],
              },
            }}
            disableAutoSelect={true}
            isTypingIndicatorEnabled={true}
          >
            <ChannelListUI
              setCurrentChannelSelected={setCurrentChannelSelected}
              currentChannelSelected={currentChannelSelected}
            />
          </ChannelListProvider>
        </Box>
      </SendbirdProvider>
    </Box>
  );
};

export default SendbirdTicketChannel;
