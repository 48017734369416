import { useState, useMemo } from 'react';
import '@sendbird/uikit-react/dist/index.css';
import { Mui } from '@onedesign/ui';
import { useSendbirdService } from 'services/SendbirdService';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { useLoginService } from 'services/LoginService';
import { ChannelListProvider } from '@sendbird/uikit-react/ChannelList/context';
import { Auth } from 'routes/path';
// import { sendbirdTab } from '../../../../@type/sendbird';
import ChannelListUI from './ChannelListUI';

const { Box } = Mui;

const SendbirdChannelNotify = () => {
  const [currentChannelSelected, setCurrentChannelSelected] =
    useState<GroupChannel>();
  const { loginUser } = useLoginService();
  const { sendbirdAccessToken, myColorSet } = useSendbirdService();
  const permissions = loginUser?.permissions || [];
  const includeAuth = (auth: string[]) =>
    auth.some((item: any) => permissions.includes(item));

  const tabs = useMemo(() => {
    const result = [];

    if (includeAuth([Auth.PARENT_MESSAGE_EDIT])) {
      result.push({ value: 'customer', label: '家長' });
    }

    return result;
  }, []);

  const [tab, setTab] = useState<string>(tabs.length > 0 ? tabs[0].value : '');
  //   const channelTypes: sendbirdTab[] = loginUser?.channelTypes || [];

  //   const channelTypesId = useMemo(() => {
  //     return channelTypes?.map(item => item.id);
  //   }, [channelTypes]);

  if (!loginUser?.staffNumber || !sendbirdAccessToken) return null;

  return (
    <Box
      sx={{
        pl: '32px',
        pr: '32px',
        pb: '120px',
        pt: '32px',
      }}
    >
      <SendbirdProvider
        appId={import.meta.env.VITE_SENDBIRD_APP_ID}
        userId={loginUser?.staffNumber}
        colorSet={myColorSet}
        accessToken={sendbirdAccessToken}
      >
        <Box
          sx={{
            display: 'flex',
            height: '100%',
          }}
        >
          <ChannelListProvider
            queries={{
              channelListQuery: {
                includeEmpty: true,
                customTypesFilter: [
                  tab === 'customer'
                    ? import.meta.env.VITE_IM_CUSTOMER_TYPE
                    : '',
                ],
              },
            }}
            disableAutoSelect={true}
            isTypingIndicatorEnabled={true}
          >
            <ChannelListUI
              setCurrentChannelSelected={setCurrentChannelSelected}
              currentChannelSelected={currentChannelSelected}
              tab={tab}
              setTab={setTab}
              tabs={tabs}
            />
          </ChannelListProvider>
        </Box>
      </SendbirdProvider>
    </Box>
  );
};

export default SendbirdChannelNotify;
