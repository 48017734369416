import { AxiosInstance } from 'axios';
// import { removeUndefinedFromObject } from 'utils/common';
// import { IPagnationParams } from '@type/common';

export default (axios: AxiosInstance, leave: string) => ({
  /**
   * 老師請假
   */
  teacherLeave(data: any): any {
    return axios.post(`${leave}/leave/teacher/duration`, data);
  },
});
