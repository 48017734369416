export interface IMemberByIdResponse {
  id: string;
  name: string;
  lastName: string;
}

export enum EInboundWebhook {
  CRM_CONTACT_GET = '/rest/23/y7zq0kv08o151zes/crm.contact.get.json',
  CRM_DEAL_GET = '/rest/23/y7zq0kv08o151zes/crm.deal.get.json',
  CRM_DEAL_FIELDS = '/rest/23/y7zq0kv08o151zes/crm.deal.fields.json',
  CRM_DEAL_UPDATE = '/rest/23/y7zq0kv08o151zes/crm.deal.update.json',
  CRM_DEAL_USERFIELD_LIST = '/rest/23/inss7kw3onnyaj97/crm.deal.userfield.list.json',
}

export enum EDealBitrixCustomFields {
  TEACHING_SYSTEM_ID = 'UF_CRM_1709539812',
  AUDITION_TIME_1 = 'UF_CRM_1711015660',
  AUDITION_TIME_2 = 'UF_CRM_1711015710',
  AUDITION_TIME_3 = 'UF_CRM_1711015751',
  AUDITION_TIME_4 = 'UF_CRM_1711016081',
  AUDITION_LINK_1 = 'UF_CRM_1709540993',
  AUDITION_LINK_2 = 'UF_CRM_1709549548',
  AUDITION_LINK_3 = 'UF_CRM_1709549560',
  AUDITION_LINK_4 = 'UF_CRM_1709549570',
  AUDITION_TEACHER_1 = 'UF_CRM_1709549602',
  AUDITION_TEACHER_2 = 'UF_CRM_1709549611',
  AUDITION_TEACHER_3 = 'UF_CRM_1709549620',
  AUDITION_TEACHER_4 = 'UF_CRM_1709549631',
  AUDITION_STUDENT_NAME_1 = 'UF_CRM_1709549675',
  AUDITION_STUDENT_NAME_2 = 'UF_CRM_1709549689',
  AUDITION_STUDENT_NAME_3 = 'UF_CRM_1709549700',
  AUDITION_STUDENT_NAME_4 = 'UF_CRM_1709549709',
  BOOK_ID_1 = 'UF_CRM_1709549773',
  BOOK_ID_2 = 'UF_CRM_1709549810',
  BOOK_ID_3 = 'UF_CRM_1709549820',
  BOOK_ID_4 = 'UF_CRM_1709549829',
  STUDENT_1_GRADE = 'UF_CRM_1709550133',
  STUDENT_2_GRADE = 'UF_CRM_1709567223',
  STUDENT_3_GRADE = 'UF_CRM_1709567195',
  STUDENT_4_GRADE = 'UF_CRM_1709567333',
}
