export enum ERemoteConfigParameter {
  MMS_TEACHER_SIGNING = 'MMS_TEACHER_SIGNING',
  ONECLUB_5519 = 'ONECLUB_5519',
  ONECLUB_5214 = 'ONECLUB_5214',
  ONECLUB_5917 = 'ONECLUB_5917',
  ONECLUB_6204 = 'ONECLUB_6204',
  ONECLUB_6237 = 'ONECLUB_6237',
  ONECLUB_6236 = 'ONECLUB_6236',
  ONECLUB_6599 = 'ONECLUB_6599',
  ONECLUB_7143 = 'ONECLUB_7143',
  ONECLUB_7167 = 'ONECLUB_7167',
  ONECLUB_7111 = 'ONECLUB_7111',
  ONECLUB_7285 = 'ONECLUB_7285',

  ONE_ANSWER = 'ONE_ANSWER',
}
