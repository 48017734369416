export enum BITRIX_URL {
  order = 'https://oneclassvn.bitrix24.com/crm/deal/details/',
}
export const EDUCATION_GRADE_MAP: Record<
  string,
  {
    label: string;
    grades: {
      label: string;
      value: number;
    }[];
  }
> = {
  E: {
    label: 'Tiểu học',
    grades: [
      {
        label: 'Lớp 1',
        value: 1,
      },
      {
        label: 'Lớp 2',
        value: 2,
      },
      {
        label: 'Lớp 3',
        value: 3,
      },
      {
        label: 'Lớp 4',
        value: 4,
      },
      {
        label: 'Lớp 5',
        value: 5,
      },
    ],
  },
  J: {
    label: 'Trung học cơ sở',
    grades: [
      {
        label: 'Lớp 6',
        value: 6,
      },
      {
        label: 'Lớp 7',
        value: 7,
      },
      {
        label: 'Lớp 8',
        value: 8,
      },
      {
        label: 'Lớp 9',
        value: 9,
      },
    ],
  },
  H: {
    label: 'Trung học phổ thông',
    grades: [
      {
        label: 'Lớp 10',
        value: 10,
      },
      {
        label: 'Lớp 11',
        value: 11,
      },
      {
        label: 'Lớp 12',
        value: 12,
      },
    ],
  },
};

export enum BITRIX_CONTACT_MAP {
  ONECLUB_ID = 'UF_CRM_1711622831',
  DESCRIPTION = 'UF_CRM_1711623585',
}
