import {
  dateFormate,
  dateTimeFormat,
  monthYearFormat,
} from 'constants/date.constants';
import {
  format,
  getTime,
  formatDistanceToNow,
  getMonth,
  getYear,
  getDate,
  getDay,
  intervalToDuration,
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  set,
  formatISO,
  parseISO,
} from 'date-fns';
import { VN_LANGUAGE, getUserLanguagePreference, t } from 'utils/i18n';

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fToDate(date: Date | string | number | undefined) {
  if (!date) {
    return '';
  }
  return format(new Date(date), dateFormate);
}
export function fToDateYYYYMM(date: Date | string | number | undefined) {
  if (date === undefined) {
    return '';
  }
  return format(new Date(date), monthYearFormat);
}

export function fCNDate(date: Date | string | number) {
  return format(new Date(date), t('dateFormat.fCNDate', 'MM月dd日 yyyy'));
}

export function fCNDateExcludeYear(date: Date | string | number) {
  return format(new Date(date), t('dateFormat.fCNDateExcludeYear', 'MM月dd日'));
}

export function fCNDateExcludeDay(date: Date | string | number) {
  return format(new Date(date), t('dateFormat.fCNDateExcludeDay', 'MM月 yyyy'));
}

export function fToTime(date: Date | string | number) {
  return format(new Date(date), 'HH:mm');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fYear(date: Date | string | number) {
  return getYear(new Date(date));
}

export function fMonth(date: Date | string | number) {
  return getMonth(new Date(date));
}

export function fDay(date: Date | string | number) {
  return getDate(new Date(date));
}

export function fDayOfWeek(date: Date | string | number) {
  return getDay(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'yyyy/MM/dd pp');
}

export function fDateTime24H(date: Date | string | number) {
  if (!date) return '';
  return format(new Date(date), dateTimeFormat);
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fDuration(duration: number) {
  return intervalToDuration({ start: 0, end: duration * 1000 });
}

export function fCurrentYear(date: Date | number) {
  return [startOfYear(date), endOfYear(date)];
}

export function fCurrentMonth(date: Date | number) {
  return [startOfMonth(date), endOfMonth(date)];
}

export function fCurrentWeek(date: Date | number) {
  return [startOfWeek(date), endOfWeek(date)];
}

export function fCurrentDay(date: Date | number) {
  return [startOfDay(date), endOfDay(date)];
}

export function fClearSeconds(date: Date | number) {
  return set(date, { milliseconds: 0, seconds: 0 });
}

export function fToISOString(date: Date | string | number) {
  return formatISO(new Date(date));
}

export function fUTCDateTime(date: Date | string | number) {
  return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
}
export function convertToISODateString(timestamp: any): string {
  const date = new Date(timestamp);
  const dateString = date.toISOString();
  return dateString;
}

export function convertToTimestamp(dateString: string): number {
  const inputDate = parseISO(dateString);
  const timestamp = inputDate.getTime();
  return timestamp;
}
export function convertStringToDateFormat(input: string): string {
  const userLanguagePreference = getUserLanguagePreference();
  if (userLanguagePreference !== VN_LANGUAGE.VI) return input;
  const trimmedInput = input.trim();
  const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
  const monthYearRegex = /^(\d{4})-(\d{2})$/;
  const dayMonthYearRegex = /^(\d{2})-(\d{2}) ~ (\d{4})-(\d{2})-(\d{2})$/;
  if (dateRegex.test(trimmedInput)) {
    return format(new Date(trimmedInput), 'dd-MM-yyyy');
  }
  if (monthYearRegex.test(trimmedInput)) {
    const [, year, month] = trimmedInput.match(monthYearRegex)!;
    return `${month}-${year}`;
  }
  if (dayMonthYearRegex.test(trimmedInput)) {
    const [, day, month, , year, date] = trimmedInput.match(dayMonthYearRegex)!;
    return `${day}-${month} ~ ${date}-${month}-${year}`;
  }
  return trimmedInput;
}

export const convertDateString = (originalDateString: string): string => {
  const parsedDate = parseISO(originalDateString);
  return format(parsedDate, 'yyyy/MM/dd HH:mm');
};
