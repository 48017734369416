import { AxiosInstance } from 'axios';
import { TApiResponse, TQueryPeriodTime } from '@type/common';
import {
  IGetCourseListParams,
  IGetCourseRecordsLatest,
  IMonthFileList,
  ICourseRecord,
  IUpdateCoursePayload,
  ICourse,
  ICourseSalaryPayload,
  ITransferCoursePatchPayload,
  ITransferCourseGetPayload,
  IGetCourseExceptionCountParams,
  IGetCourseCreditCountParams,
  IGetCourseCreditCountResponse,
  IOrganization,
  IOrganizationPayload,
  IGroupPayload,
  IGroup,
  IOneLinkPlusOrganizationsResponse,
  IOneLinkPlusSubOrganizationsPayload,
  IOneLinkPlusSubOrganizationsResponse,
  IOneLinkPlusSubOrganizationCourseListPayload,
  IOneLinkPlusSubOrganizationCourseListResponse,
  IOneLinkPlusGroupNamesListPayload,
  IOneLinkPlusGroupNamesListResponse,
  IPostCourseAcceptPayload,
  IGetOmsCourseResponse,
  IGetOmsCourseListParams,
  ICancelAllCoursePayload,
} from '@type/course.type';
import {
  IGetCreditRecordsParams,
  IGetCreditRecordResponse,
  IGetCreditsParams,
  IGetCreditsResponse,
  IUpdateCreditParams,
  IUpdateCreditByManualParams,
  IUpdateCreditResponse,
  IGetExpiredRecordParams,
  IGetExpiredRecordResponse,
  IUpdateCreditExpiredParams,
  IUpdateCreditExpiredResponse,
  IGetCourseResponse,
  IGetCourseExceptionResponse,
  AllDepartmentAuditionsResponse,
  ICheckCourseExistResponse,
  IGetCourseCountResponse,
  ICreateManualCreditParams,
  ICreateManualCreditResponse,
  IRemoveManualCreditParams,
  IRemoveManualCreditResponse,
  IConvertCreditParams,
  IConvertCreditResponse,
  IConvertCreditForUpgradeEducationSystemPayload,
  IConvertCreditForUpgradeEducationSystemResponse,
  IUserExamResponse,
  IUserExamParams,
  ITutorExamParams,
  ITutorExamResponse,
  IPostExamResponse,
  IPostExamParams,
  IGetCreditRecordsByPayAtParams,
  IGetCreditRecordsByPayAtResponse,
} from '@type/customer/customer.type';
import { TFeedbackListPayload, TFeedbackPayload } from '@type/feedback.type';
import { IGetSchoolParams, ISchoolResponse } from '@type/school.type';
import {
  TBatchUpdateRewardMerchandiseRecordParams,
  TBatchUpdateRewardMerchandiseRecordResponse,
  TGetRewardMerchandiseRecordParams,
  TGetRewardMerchandiseRecordResponse,
  TRewardMerchandiseRecords,
  TUpdateRewardMerchandiseRecordParams,
  TExportRewardMerchandiseRecordParams,
  TExportRewardMerchandiseRecordResponse,
} from '@type/rewardMerchandise.type';
import { stringify } from 'query-string';
import {
  IGetCustomerCreditByOneClubId,
  IGetUnearnedRevenueDetailListParams,
  IGetUnearnedRevenueDetailListResponse,
  IExportRecognizedRevenueParams,
  IExportRecognizedRevenueResponse,
} from '@type/revenue';
import {
  ILaborCostExportExcelParams,
  ILaborCostTotalParams,
  ILaborCostTotalResponse,
  ILaborCostSalaryListParams,
  ILaborCostSalaryListResponse,
  ILaborCostBonusListParams,
  ILaborCostBonusListResponse,
  ILaborCostExportExcelResponse,
  ILaborCostBonusTotalParams,
  ILaborCostBonusTotalResponse,
} from '@type/financial/laborcost.type';
import {
  ICourseReview,
  IUpdateCourseReviewParams,
  ICourseReviewLogResponse,
  ICourseReviewListParams,
  ICourseReviewListResponse,
  ICourseReviewCountResponse,
  ICourseReviewCountParams,
  ICourseReviewResultParams,
  IScheduledExportParams,
  ICourseReviewResult,
  ICourseReviewLogParams,
  ICourseReviewAbnormalResponse,
  ICourseReviewAbnormalParams,
} from '@type/audition/course.type';
import {
  IBatchScheduledCourse,
  IGetBatchScheduledCourseListParams,
  IGetBatchScheduledCourseListResponse,
  ICreateBatchScheduledCourseParams,
  ICreateBatchScheduledCourseResponse,
  IValidateStudentCourseParams,
  IValidateTeachersCourseParams,
  IUserSkipScheduleCourses,
  IGetBatchCourseLogResponse,
  IWithholdingCreditResponse,
  IWithholdingCreditParams,
  IBatchExportParams,
  IBatchExportResponse,
} from '@type/batchScheduledCourse';
import {
  IGetAdvertisementListParams,
  IGetAdvertisementListResponse,
  IAdvertisement,
  IGetAdvertisementRecordsParams,
  IGetAdvertisementRecordsResponse,
  ICreateAdvertisementPayload,
  IUpdateAdvertisementPayload,
} from '@type/advertisement.type';
import {
  IGetWithholdingCreditParams,
  IWithholdingCredit,
} from '@type/credit.type';
import {
  ICreditRecordDataCenterParams,
  ICreditRecordDataCenterResponse,
} from '@type/datacenter/dataCenter.type';
import {
  getMemberCustomersChannelsParams,
  getMemberCustomersChannelsResponse,
} from '@type/sendbird';
import qs from 'qs';

interface IChildEduSubject {
  code: string;
  namne: string;
}
interface IItemEduSubject {
  E: IChildEduSubject[];
  J: IChildEduSubject[];
  H: IChildEduSubject[];
}
interface IEduSubject {
  eduMap: IChildEduSubject[];
  eduSubject: IItemEduSubject;
  eduGrade: IItemEduSubject;
}
interface IExportTeacherList {
  data: BlobPart;
}

interface IGetMemberForAuditionReservationParams {
  formalizePhone?: string;
  phone?: string;
}
interface IMemberForAuditionReservation {
  id: string;
  name: string;
  oneClubId: string;
  email: string;
  phone: string;
  contactId: string;
  consultant: string;
}

interface IMemberForAuditionReservationResponse {
  customers: IMemberForAuditionReservation[];
}

export default (axios: AxiosInstance, oneClassApiGateway: string) => ({
  /*
  update processedStatus:
  * */
  updateProcessedStatus(id: string): TApiResponse<ICourse> {
    return axios.patch(
      `${oneClassApiGateway}/backend/batch-scheduled-course/handle-update-batchCourse-status/${id}`,
    );
  },

  eduSubjectVN(): TApiResponse<IEduSubject> {
    return axios.get(`${oneClassApiGateway}`);
  },

  /**
   * 取得 會員堂數列表
   */
  getCredits(params: IGetCreditsParams): TApiResponse<IGetCreditsResponse> {
    return axios.get(`${oneClassApiGateway}/credit`, { params });
  },

  /**
   * export course vn
   *
   */
  exportCourseVN(params: TQueryPeriodTime): TApiResponse<any> {
    return axios.get(`${oneClassApiGateway}/course/vn-export-excel`, {
      params,
    });
  },
  /**
   * 建立 課程
   */
  createCourse(params: any): any {
    return axios.post(`${oneClassApiGateway}/course`, { ...params });
  },
  /**
   * 取得 單一課程
   */
  getCourseById(id: string): TApiResponse<ICourse> {
    return axios.get(`${oneClassApiGateway}/course/${id}`);
  },
  /**
   * 編輯 課程
   */
  updateCourse(id: string, data: IUpdateCoursePayload): TApiResponse<any> {
    return axios.patch(`${oneClassApiGateway}/course/${id}`, data);
  },
  /**
   * 取消 課程
   */
  cancelCourse(id: string, data: any): any {
    return axios.patch(`${oneClassApiGateway}/course/${id}/cancel`, data);
  },
  /**
   * 取消 所有課程
   */
  cancelAllCourse(id: string, data: ICancelAllCoursePayload): any {
    return axios.post(
      `${oneClassApiGateway}/backend/batch-scheduled-course/${id}/cancelAllCourse`,
      data,
    );
  },
  /**
   * 異常 課程
   */
  exceptionCourse(id: string, data: any): any {
    return axios.post(`${oneClassApiGateway}/course/${id}/exception`, data);
  },
  /**
   * 待處理 課程
   */
  handleCourse(id: string, data: any): TApiResponse<any> {
    return axios.post(`${oneClassApiGateway}/course/${id}/handle`, data);
  },
  /**
   * 轉移課程
   */
  transferCourse(data: ITransferCoursePatchPayload): TApiResponse<any> {
    return axios.patch(`${oneClassApiGateway}/course/transfer-course`, data);
  },
  /**
   * 轉移課程列表（與課程列表差異為 courseType 可以帶陣列）
   */
  getTransferCourseList(params: ITransferCourseGetPayload): TApiResponse<any> {
    return axios.get(`${oneClassApiGateway}/course/transfer-course`, {
      params,
    });
  },
  /**
   * 刪除課程（草稿）
   */
  deleteCourseByDraft(id: string): any {
    return axios.delete(`${oneClassApiGateway}/course/${id}`);
  },
  /**
   * 編輯課程薪資
   */
  updateSalaryIssueGroup(
    id: string,
    date: ICourseSalaryPayload,
  ): TApiResponse<any> {
    return axios.patch(
      `${oneClassApiGateway}/course/${id}/salaryIssueGroup`,
      date,
    );
  },
  /**
   * 取得 會員堂數紀錄列表
   */
  getCreditRecords(
    params: IGetCreditRecordsParams,
  ): TApiResponse<IGetCreditRecordResponse> {
    return axios.get(`${oneClassApiGateway}/credit-record`, { params });
  },

  getCreditRecordDataCenter(
    params: ICreditRecordDataCenterParams,
  ): TApiResponse<ICreditRecordDataCenterResponse> {
    return axios.get(`${oneClassApiGateway}/credit-record/data-center`, {
      params,
    });
  },

  /**
   * 銷售堂數計算供銷售數據使用
   */
  getCreditRecordsByPayAt(
    params: IGetCreditRecordsByPayAtParams,
  ): TApiResponse<IGetCreditRecordsByPayAtResponse> {
    return axios.get(`${oneClassApiGateway}/credit-record/order-pay-at`, {
      params,
    });
  },

  // 拿機構名稱
  getOrganization(
    payload: IOrganizationPayload,
  ): TApiResponse<IOrganization[]> {
    return axios.get(
      `${oneClassApiGateway}/external/course/link-tutorial-school/organizations`,
      {
        params: payload,
      },
    );
  },

  // 拿班級名稱
  getGroup(payload: IGroupPayload): TApiResponse<IGroup[]> {
    return axios.get(
      `${oneClassApiGateway}/external/course/link-tutorial-school/groups`,
      {
        params: payload,
      },
    );
  },

  // 拿 OneLink+ 機構名稱
  getOneLinkPlusOrganizations(): TApiResponse<
    IOneLinkPlusOrganizationsResponse[]
  > {
    return axios.get(
      `${oneClassApiGateway}/external/course/link-tutorial-school/linkPlusOrganizations`,
    );
  },

  // 拿 OneLink+ 分校名稱
  getOneLinkPlusSubOrganizations(
    payload: IOneLinkPlusSubOrganizationsPayload,
  ): TApiResponse<IOneLinkPlusSubOrganizationsResponse[]> {
    return axios.get(
      `${oneClassApiGateway}/external/course/link-tutorial-school/linkPlusSubOrganizations`,
      { params: payload },
    );
  },

  // 拿 OneLink+ 分校機構的課程列表
  getOneLinkPlusSubOrganizationCourseList(
    payload: IOneLinkPlusSubOrganizationCourseListPayload,
  ): TApiResponse<IOneLinkPlusSubOrganizationCourseListResponse[]> {
    return axios.get(
      `${oneClassApiGateway}/external/course/link-tutorial-school/linkPlusSubOrganizationCourseList`,
      { params: payload },
    );
  },

  // 拿 OneLink+ 課程名稱（MMS的班級）
  getOneLinkPlusGroupNamesList(
    payload: IOneLinkPlusGroupNamesListPayload,
  ): TApiResponse<IOneLinkPlusGroupNamesListResponse[]> {
    return axios.get(
      `${oneClassApiGateway}/external/course/link-tutorial-school/linkPlusGroupNamesList`,
      { params: payload },
    );
  },

  /**
   * 取得 課程列表
   */
  getCourseList(
    params: IGetCourseListParams,
  ): TApiResponse<IGetCourseResponse> {
    return axios.get(`${oneClassApiGateway}/course`, { params });
  },
  /**
   * 取得 課程列表數量
   */
  getCoursesCount(
    params: IGetCourseListParams,
  ): TApiResponse<IGetCourseCountResponse> {
    return axios.get(`${oneClassApiGateway}/course/count`, { params });
  },
  /**
   * 取得完課的堂數總計
   */
  getCourseCreditCount(
    params: IGetCourseCreditCountParams,
  ): TApiResponse<IGetCourseCreditCountResponse> {
    return axios.get(`${oneClassApiGateway}/course/count-credit`, { params });
  },
  /**
   * 取得 待確認數量
   */
  getCourseExceptionCount(
    params?: IGetCourseExceptionCountParams,
  ): TApiResponse<IGetCourseExceptionResponse> {
    return axios.get(`${oneClassApiGateway}/course/exception-count`, {
      params,
    });
  },
  /**
   * 檢查此時段是否有其他課程
   */
  checkCourseExist(
    params: IGetCourseListParams,
  ): TApiResponse<ICheckCourseExistResponse> {
    return axios.get(`${oneClassApiGateway}/course/checkExist`, { params });
  },
  /**
   * 新增 課後回饋
   */
  postCourseFeedback(params: TFeedbackPayload): any {
    return axios.post(`${oneClassApiGateway}/feedback`, params);
  },
  /**
   * 取得 課後回饋
   */
  getCourseFeedback(params: TFeedbackListPayload): any {
    return axios.get(`${oneClassApiGateway}/feedback`, { params });
  },
  /**
   * 編輯 課後回饋
   */
  updateCourseFeedback(id: string, params: TFeedbackPayload): any {
    return axios.put(`${oneClassApiGateway}/feedback/${id}`, params);
  },

  getStudentLearnedResources(studentId: string): any {
    return axios.get(
      `${oneClassApiGateway}/feedback/student-learned-resource/${studentId}`,
    );
  },
  /**
   * 課程修改記錄列表
   */
  getCourseRecords(id: string): TApiResponse<ICourseRecord[]> {
    return axios.get(`${oneClassApiGateway}/course/${id}/records`);
  },
  /**
   * 取得課程列表最新的修改記錄
   */
  getCourseRecordsLatest(
    courseIds: string[],
  ): TApiResponse<IGetCourseRecordsLatest[]> {
    const querystring = stringify({ courseIds }, { arrayFormat: 'index' });
    return axios.get(
      `${oneClassApiGateway}/course/records/latest?${querystring}`,
    );
  },

  /**
   * 匯出 連續課程已失效課程
   */
  async exportExpaired(
    payload: IBatchExportParams,
  ): TApiResponse<IBatchExportResponse> {
    return axios.get(
      `${oneClassApiGateway}/backend/batch-scheduled-course/export`,
      {
        params: payload,
      },
    );
  },

  /**
   * 更新會員堂數
   */
  updateCredit(
    owner: string,
    data: IUpdateCreditParams,
  ): TApiResponse<IUpdateCreditResponse> {
    return axios.patch(`${oneClassApiGateway}/credit/${owner}`, data);
  },
  /**
   * 手動變更完課堂數
   */
  updateCreditByManual(
    data: IUpdateCreditByManualParams,
  ): TApiResponse<IUpdateCreditResponse> {
    return axios.post(
      `${oneClassApiGateway}/credit/updateCreditForCourseManual`,
      data,
    );
  },
  /**
   * 轉換會員堂數
   */
  convertCredit(
    data: IConvertCreditParams,
  ): TApiResponse<IConvertCreditResponse> {
    return axios.post(`${oneClassApiGateway}/credit/convertCredit`, data);
  },
  /**
   * 更新會員堂數到期日
   */
  updateCreditExpired(
    owner: string,
    data: IUpdateCreditExpiredParams,
  ): TApiResponse<IUpdateCreditExpiredResponse> {
    return axios.patch(`${oneClassApiGateway}/credit/expired/${owner}`, data);
  },
  /**
   * 異動堂數，建立會員堂數
   */
  createManualCredit(
    params: ICreateManualCreditParams,
  ): TApiResponse<ICreateManualCreditResponse> {
    return axios.post(`${oneClassApiGateway}/credit/manualCreate`, params);
  },
  /**
   * 異動堂數，刪除會員堂數
   */
  removeManualCredit(
    params: IRemoveManualCreditParams,
  ): TApiResponse<IRemoveManualCreditResponse> {
    return axios.patch(`${oneClassApiGateway}/credit/manualRemove`, params);
  },
  /**
   * 取得修改過期期間紀錄
   */
  getExpiredRecord(
    params: IGetExpiredRecordParams,
  ): TApiResponse<IGetExpiredRecordResponse> {
    return axios.get(`${oneClassApiGateway}/expired-record`, { params });
  },
  /**
   *
   * 更新同訂單內商品轉換堂數
   */
  convertCreditForUpgradeEducationSystem(
    payload: IConvertCreditForUpgradeEducationSystemPayload,
  ): TApiResponse<IConvertCreditForUpgradeEducationSystemResponse> {
    return axios.post(
      `${oneClassApiGateway}/credit/convertCreditForUpgradeEducationSystem`,
      payload,
    );
  },
  /**
   *
   * 檢查學生預扣堂數
   */
  getWithholdingCredit(
    params: IGetWithholdingCreditParams,
  ): TApiResponse<IWithholdingCredit[]> {
    return axios.get(`${oneClassApiGateway}/credit/validateWithholdingCredit`, {
      params,
    });
  },
  /**
   * get schools 取得學校列表
   */
  getSchools(params: IGetSchoolParams): TApiResponse<ISchoolResponse> {
    return axios.get(`${oneClassApiGateway}/school`, { params });
  },

  /**
   * 下載課程excel
   */
  downloadCourseExcel(params: IGetCourseListParams): any {
    return axios.get(`${oneClassApiGateway}/course/exportExcel`, { params });
  },

  /**
   * 取得課程測驗
   */
  getCourseExams(id: string): any {
    return axios.get(`${oneClassApiGateway}/course/${id}/exams`);
  },
  /**
   * 取得課程日誌
   */
  getCourseDiaries(id: string): any {
    return axios.get(`${oneClassApiGateway}/course/${id}/diaries`);
  },
  /**
   * 新增課程日誌紀錄
   */
  createCourseDiaries(id: string, data: any): any {
    return axios.post(`${oneClassApiGateway}/course/${id}/diaries`, data);
  },
  /**
   * 更新課程日誌
   */
  updateCourseDiaries(id: string, diaryId: string, data: any): any {
    return axios.patch(
      `${oneClassApiGateway}/course/${id}/diaries/${diaryId}`,
      data,
    );
  },
  /**
   * 刪除課程日誌
   */
  deleteCourseDiaries(id: string, diaryId: string): any {
    return axios.delete(
      `${oneClassApiGateway}/course/${id}/diaries/${diaryId}`,
    );
  },
  /** 取得同部門所有試聽課程 */
  getDepartmentsAuditions(
    params: IGetCourseListParams,
  ): TApiResponse<AllDepartmentAuditionsResponse> {
    return axios.get(`${oneClassApiGateway}/course/departmentauditions`, {
      params,
    });
  },
  /**
   * 取得腳本產出FileList
   */
  getMonthFileList(date: string): TApiResponse<IMonthFileList> {
    return axios.get(`${oneClassApiGateway}/course/cron/getMonthFileList`, {
      params: { date },
    });
  },

  /**
   * 下載月或週報表資料
   */
  async downloadCourseRecord(name: string, url: string): Promise<any> {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.download = `${name}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  },
  /**
   * 批次匯入
   */
  uploadCourse(file: File): Promise<any> {
    const form = new FormData();
    form.append('file', file);
    return axios.post(
      `${oneClassApiGateway}/course/batchImportCourseExcel`,
      form,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
  },
  /**
   * 獎品兌換列表
   */
  getRewardMerchandiseRecordList(
    params: TGetRewardMerchandiseRecordParams,
  ): TApiResponse<TGetRewardMerchandiseRecordResponse> {
    return axios.get(
      `${oneClassApiGateway}/backend/reward-merchandise-record`,
      { params },
    );
  },
  /**
   * 取得單一獎品兌換明細
   */
  getRewardMerchandiseRecordById(
    id: string,
  ): TApiResponse<TRewardMerchandiseRecords> {
    return axios.get(
      `${oneClassApiGateway}/backend/reward-merchandise-record/${id}`,
    );
  },
  /**
   * 編輯獎品兌換明細
   */
  updateRewardMerchandiseRecord(
    id: string,
    params: TUpdateRewardMerchandiseRecordParams,
  ): TApiResponse<TRewardMerchandiseRecords> {
    return axios.patch(
      `${oneClassApiGateway}/backend/reward-merchandise-record/${id}`,
      params,
    );
  },
  /**
   * 批次更新兌換請求
   */
  batchUpdateRewardMerchandiseRecord(
    params: TBatchUpdateRewardMerchandiseRecordParams,
  ): TApiResponse<TBatchUpdateRewardMerchandiseRecordResponse> {
    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('status', params.status);
    return axios.post(
      `${oneClassApiGateway}/backend/reward-merchandise-record/batchUpdate`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
  },
  /**
   * 匯出 excel
   */
  exportRewardMerchandiseRecord(
    params?: TExportRewardMerchandiseRecordParams,
  ): TApiResponse<TExportRewardMerchandiseRecordResponse> {
    return axios.post(
      `${oneClassApiGateway}/backend/reward-merchandise-record/export`,
      undefined,
      {
        params,
      },
    );
  },

  /**
   * 取得課程單堂認列列表
   */
  getUnearnedRevenueDetailList(
    params: IGetUnearnedRevenueDetailListParams,
  ): TApiResponse<IGetUnearnedRevenueDetailListResponse> {
    return axios.get(`${oneClassApiGateway}/backend/financial-courses`, {
      params,
    });
  },
  /**
   * 取得單一會員課堂資料
   */
  getCustomerCreditByOneClubId(
    id: string,
  ): TApiResponse<IGetCustomerCreditByOneClubId> {
    return axios.get(`${oneClassApiGateway}/credit/${id}`);
  },

  /**
   * 匯出 月認列收入表
   */
  async exportRecognizedRevenue(
    payload: IExportRecognizedRevenueParams,
  ): TApiResponse<IExportRecognizedRevenueResponse> {
    return axios.get(
      `${oneClassApiGateway}/backend/financial-courses/exportExcel`,
      {
        params: payload,
      },
    );
  },

  // 匯出勞務成本表excel
  async exportLaborCostList(
    payload: ILaborCostExportExcelParams,
  ): TApiResponse<ILaborCostExportExcelResponse> {
    return await axios.get(
      `${oneClassApiGateway}/backend/salary/export-services-cost-excel`,
      {
        params: payload,
      },
    );
  },

  getLaborCostBonusTotal(
    payload: ILaborCostBonusTotalParams,
  ): TApiResponse<ILaborCostBonusTotalResponse> {
    return axios.get(`${oneClassApiGateway}/backend/bonus/total`, {
      params: payload,
    });
  },

  getLaborCostSalaryTotal(
    payload: ILaborCostTotalParams,
  ): TApiResponse<ILaborCostTotalResponse> {
    return axios.get(
      `${oneClassApiGateway}/backend/salary/get-salary-amount-total`,
      {
        params: payload,
      },
    );
  },

  getLaborCostSalaryList(
    payload: ILaborCostSalaryListParams,
  ): TApiResponse<ILaborCostSalaryListResponse> {
    return axios.get(
      `${oneClassApiGateway}/backend/salary/get-teacher-salary-labor-cost`,
      {
        params: payload,
        paramsSerializer: params => {
          return qs.stringify(params);
        },
      },
    );
  },

  getLaborCostBonusList(
    payload: ILaborCostBonusListParams,
  ): TApiResponse<ILaborCostBonusListResponse> {
    return axios.get(
      `${oneClassApiGateway}/backend/salary/get-teacher-bonus-labor-cost`,
      {
        params: payload,
      },
    );
  },

  // 取得測驗列表
  getUserExams(payload: IUserExamParams): TApiResponse<IUserExamResponse> {
    return axios.get(`${oneClassApiGateway}/exam/user-exams`, {
      params: payload,
    });
  },

  // 點派發測驗，取得自己可派發的學生列表
  getTutorExams(payload: ITutorExamParams): TApiResponse<ITutorExamResponse> {
    return axios.get(`${oneClassApiGateway}/member/students/tutor/me`, {
      params: payload,
    });
  },

  // 派發測驗
  postExams(payload: IPostExamParams): TApiResponse<IPostExamResponse> {
    return axios.post(`${oneClassApiGateway}/exam/user-exams`, payload);
  },

  /**
   * Ask the teacher if he receives the order
   */
  postCourseAccept(
    id: string,
    payload: IPostCourseAcceptPayload,
  ): TApiResponse<ICourse> {
    return axios.post(`${oneClassApiGateway}/course/${id}/isAccept`, payload);
  },
  /**
   * 課程審核
   */
  getCourseReviewById(reviewId: string): TApiResponse<ICourseReview> {
    return axios.get(`${oneClassApiGateway}/backend/course-review/${reviewId}`);
  },
  /**
   * 審核完課標籤
   */
  updateCourseReview(
    reviewId: string,
    data: IUpdateCourseReviewParams,
  ): TApiResponse<ICourseReview[]> {
    return axios.patch(
      `${oneClassApiGateway}/backend/course-review/${reviewId}`,
      data,
    );
  },
  /**
   * 審核異動紀錄
   */
  getCourseReviewLog(
    data: ICourseReviewLogParams,
  ): TApiResponse<ICourseReviewLogResponse> {
    return axios.get(`${oneClassApiGateway}/backend/course-review/logs`, {
      params: data,
    });
  },
  /**
   * 重新審核異常完課
   */
  getReviewAbnormalCompletion(
    courseId: string,
    payload: ICourseReviewAbnormalParams,
  ): TApiResponse<ICourseReviewAbnormalResponse> {
    return axios.post(
      `${oneClassApiGateway}/backend/course-review/adminCreateOrUpdateReview/${courseId}`,
      payload,
    );
  },

  /**
   * 課程審核列表
   */
  getCourseReviewList(
    data: ICourseReviewListParams,
  ): TApiResponse<ICourseReviewListResponse> {
    return axios.get(
      `${oneClassApiGateway}/backend/course-review/review-list`,
      {
        params: data,
      },
    );
  },
  /**
   * 單一課程審核結果
   */
  getCourseReviewResult(
    data: ICourseReviewResultParams,
  ): TApiResponse<ICourseReviewResult> {
    return axios.get(`${oneClassApiGateway}/backend/course-review/one`, {
      params: data,
    });
  },
  /**
   * 課程審核列表需審核數量
   */
  getCourseReviewCount(
    data: ICourseReviewCountParams,
  ): TApiResponse<ICourseReviewCountResponse> {
    return axios.get(
      `${oneClassApiGateway}/backend/course-review/review-list/department-count`,
      { params: data },
    );
  },
  /**
   * Scheduled export excel
   */
  exportScheduledExcel(
    data: IScheduledExportParams,
  ): TApiResponse<IExportTeacherList> {
    return axios.get(
      // `${oneClassApiGateway}/course/teacher-prime-time-export-excel`,{params: {date: "2023-08-28T12:34:56+00:00"}}
      `${oneClassApiGateway}/course/teacher-prime-time-export-excel`,
      { params: data },
    );
  },

  /**
   * Batch Scheduled Course
   */
  getBatchScheduledCourseList(
    data: IGetBatchScheduledCourseListParams,
  ): TApiResponse<IGetBatchScheduledCourseListResponse> {
    return axios.get(`${oneClassApiGateway}/backend/batch-scheduled-course`, {
      params: data,
    });
  },
  /**
   * create Batch Scheduled Course
   */
  postBatchScheduledCourse(
    payload: ICreateBatchScheduledCourseParams,
  ): TApiResponse<ICreateBatchScheduledCourseResponse> {
    return axios.post(
      `${oneClassApiGateway}/backend/batch-scheduled-course`,
      payload,
    );
  },
  /**
   * 檢查學生排課時間
   */
  validateStudentCourse(
    data: IValidateStudentCourseParams,
  ): TApiResponse<IUserSkipScheduleCourses> {
    return axios.post(
      `${oneClassApiGateway}/course/validate-student-course-date`,
      data,
    );
  },
  /**
   * 檢查教師排課時間
   */
  validateTeachersCourse(
    data: IValidateTeachersCourseParams,
  ): TApiResponse<IUserSkipScheduleCourses> {
    return axios.post(
      `${oneClassApiGateway}/course/validate-teachers-course-date`,
      data,
    );
  },
  getBatchScheduledCourseById(id: string): TApiResponse<IBatchScheduledCourse> {
    return axios.get(
      `${oneClassApiGateway}/backend/batch-scheduled-course/${id}`,
    );
  },
  cancelInvitationBatchScheduledCourseById(
    id: string,
    payload: { reason: string },
  ): TApiResponse<any> {
    return axios.post(
      `${oneClassApiGateway}/backend/batch-scheduled-course/${id}/cancel`,
      payload,
    );
  },

  getBatchScheduledCourseLogsById(
    id: string,
  ): TApiResponse<IGetBatchCourseLogResponse> {
    return axios.get(`${oneClassApiGateway}/course/batchCourse/${id}/logs`);
  },

  // 建立廣告
  createAdvertisement(
    payload: ICreateAdvertisementPayload,
  ): TApiResponse<IAdvertisement> {
    return axios.post(
      `${oneClassApiGateway}/backend/marketing/advertisements`,
      payload,
    );
  },

  // 取得廣告列表
  getAdvertisementList(
    payload: IGetAdvertisementListParams,
  ): TApiResponse<IGetAdvertisementListResponse> {
    return axios.get(`${oneClassApiGateway}/backend/marketing/advertisements`, {
      params: payload,
    });
  },

  // 更新廣告
  updateAdvertisement(
    id: string,
    payload: IUpdateAdvertisementPayload,
  ): TApiResponse<IAdvertisement> {
    return axios.patch(
      `${oneClassApiGateway}/backend/marketing/advertisements/${id}`,
      payload,
    );
  },

  // 刪除廣告
  deleteAdvertisement(id: string): TApiResponse<IAdvertisement> {
    return axios.delete(
      `${oneClassApiGateway}/backend/marketing/advertisements/${id}`,
    );
  },

  // 取得單支廣告
  getAdvertisementById(id: string): TApiResponse<IAdvertisement> {
    return axios.get(
      `${oneClassApiGateway}/backend/marketing/advertisements/${id}`,
    );
  },

  // 取得廣告編輯紀錄列表
  getAdvertisementRecords(
    id: string,
    payload: IGetAdvertisementRecordsParams,
  ): TApiResponse<IGetAdvertisementRecordsResponse> {
    return axios.get(
      `${oneClassApiGateway}/backend/marketing/advertisements/${id}/logs`,
      {
        params: payload,
      },
    );
  },

  // 取得預扣堂數
  getValidationWithholdingCredits(
    params: IWithholdingCreditParams,
  ): TApiResponse<IWithholdingCreditResponse[]> {
    return axios.get(`${oneClassApiGateway}/credit/validateWithholdingCredit`, {
      params,
    });
  },
  // 檢查oneClubId是否存在
  getCheckOneClubID(id: string): TApiResponse<boolean> {
    return axios.get(`${oneClassApiGateway}/member/${id}/available`);
  },

  // oms-course列表
  getOmsCourse(
    params: IGetOmsCourseListParams,
  ): TApiResponse<IGetOmsCourseResponse> {
    return axios.get(`${oneClassApiGateway}/oms-courses`, {
      params,
    });
  },

  // 模糊搜尋符合該學生姓名的姓名＋OCID
  getStudentsUseName(limit: number, name: string): TApiResponse<any> {
    return axios.get(`${oneClassApiGateway}/backend/member/studentsUseName`, {
      params: {
        limit,
        name,
      },
    });
  },

  // for 試聽預約撈存在於 mms 的會員
  getMemberForAuditionReservation(
    payload: IGetMemberForAuditionReservationParams,
  ): TApiResponse<IMemberForAuditionReservationResponse> {
    return axios.get(`${oneClassApiGateway}/backend/member`, {
      params: payload,
    });
  },

  // 取得會員訊息頻道管理家長列表
  getMemberCustomersChannels(
    params: getMemberCustomersChannelsParams,
  ): TApiResponse<getMemberCustomersChannelsResponse> {
    return axios.get(`${oneClassApiGateway}/member/customers`, {
      params,
    });
  },
});
