import { base64ToBlob } from 'utils/transfer';

/* 檔案副檔名 */
export enum EFILE_EXTENSION {
  XLSX = 'xlsx',
  PDF = 'pdf',
  PNG = 'png',
  TXT = 'txt',
}

enum EFILE_TYPE {
  XLSX = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64',
  PDF = 'data:application/pdf;base64',
  PNG = 'data:image/png;base64',
  TXT = 'data:text/plain;base64',
}

const FILE_TYPE = {
  [EFILE_EXTENSION.XLSX]: EFILE_TYPE.XLSX,
  [EFILE_EXTENSION.PDF]: EFILE_TYPE.XLSX,
  [EFILE_EXTENSION.PNG]: EFILE_TYPE.PNG,
  [EFILE_EXTENSION.TXT]: EFILE_TYPE.TXT,
};
function getLinkSource(
  fileSource: string,
  fileExtension = EFILE_EXTENSION.TXT,
) {
  const contentType = FILE_TYPE[fileExtension];
  const hasSecureProtocol =
    fileSource.includes('http://') || fileSource.includes('https://');
  const linkSource = hasSecureProtocol
    ? fileSource
    : `${contentType},${fileSource}`;
  return linkSource;
}
/**
 * 下載檔案功能
 * @param fileSource (string) 檔案來源
 * @param fileName (string) 檔案名稱
 * @param fileExtension (EFILE_EXTENSION) 檔案副檔名
 */
export function downloadFile(
  fileSource: string,
  fileName: string,
  fileExtension = EFILE_EXTENSION.TXT,
) {
  const linkSource = getLinkSource(fileSource, fileExtension);
  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.target = '_blank';
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function downloadFileByBlob(fileName: string, data: any) {
  const file = base64ToBlob(data);
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = url;
  link.download = fileName;
  link.click();
  document.body.removeChild(link);
}

export function downloadFileBySource(fileName: string, data: any) {
  const link = document.createElement('a');
  const base64Regex = /^[A-Za-z0-9+/=]+$/;

  if (base64Regex.test(data)) {
    const file = base64ToBlob(data);
    link.href = window.URL.createObjectURL(new Blob([file]));
    link.download = fileName;
  } else {
    const blob = new Blob([data as unknown as BlobPart]);
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
  }

  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const downloadXlsxFile = (fileName: string, blob: Blob) => {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = URL.createObjectURL(blob);
  link.download = `${fileName}.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
