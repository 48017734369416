import React from 'react';
import { Icon, SvgName } from 'components/Icon';
import { useTheme } from '@mui/material/styles';
import { Mui } from '@onedesign/ui';
const { Tabs, Tab, Box, Button } = Mui;

export interface ITab {
  value: string;
  label: string;
  action?: () => void;
}

interface IOperate {
  icon: SvgName;
  text?: string;
  style?: 'text' | 'outlined' | 'contained';
  action: () => any;
  disabled?: boolean;
}

type Props = {
  selectTab: string;
  setSelectTab: (arg: string) => void;
  tabs: ITab[];
  operates?: IOperate[];
};

export default ({ selectTab, setSelectTab, tabs, operates }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.grey[500_24]}`,
      }}
    >
      <Tabs
        value={selectTab}
        onChange={(event: React.SyntheticEvent, newValue: string) =>
          setSelectTab(newValue)
        }
      >
        {tabs.map(item => (
          <Tab key={item.value} value={item.value} label={item.label} />
        ))}
      </Tabs>
      {operates &&
        operates.map(operate => (
          <Box key={operate.icon}>
            {operate?.disabled && (
              <Button
                variant={operate.style || 'text'}
                key={operate.icon}
                startIcon={<Icon name={operate.icon} />}
                onClick={operate.action}
              >
                {operate.text}
              </Button>
            )}
          </Box>
        ))}
    </Box>
  );
};
