import { intersection } from 'lodash';
import path, {
  dataCentralRead,
  audition,
  electroniccommerce,
  authority,
  Auth,
  customerVisitor,
  teachersmanagement,
  financial,
  report,
  badgeCentral,
  tutorialCenterRead,
} from 'routes/path';

export const createPermissionChecker = (userPermissions: Auth[]) => {
  return (requiredPermission: Auth[]) => {
    return intersection(userPermissions, requiredPermission).length > 0;
  };
};
export const checkPermissionsInAuth =
  (permissions: string[]) => (auth: string[]) => {
    return (
      permissions.length > 0 &&
      auth.some((item: any) => permissions.includes(item))
    );
  };
export const resolveAuthorizedPath = (permissions: string[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (includeAuth(dataCentralRead)) {
    return path.DATA_CENTRAL;
  }
  if (includeAuth(customerVisitor)) {
    return path.CUSTOMER;
  }
  if (includeAuth(audition)) {
    return path.AUDITION;
  }
  if (includeAuth(tutorialCenterRead)) {
    return path.TUTORIAL_CENTER_FORMAL_COURSE;
  }

  if (includeAuth(electroniccommerce)) {
    return path.ELECTRONICCOMMERCE;
  }
  if (includeAuth(teachersmanagement)) {
    return path.TEACHER_MANAGEMENT;
  }
  if (includeAuth(report)) {
    return path.REPORT;
  }
  if (includeAuth(financial)) {
    return path.FINANCIAL;
  }
  if (includeAuth(authority)) {
    return path.AUTHORITY;
  }
  if (includeAuth(badgeCentral)) {
    return path.MEDAL_CENTRAL;
  }

  return path.PERMISSION_ABNORMAL;
};

export const routerTo = (
  permissions: string[],
  navigate: (path: any) => void,
) => navigate(resolveAuthorizedPath(permissions));
