import type { GroupChannel } from '@sendbird/chat/groupChannel';
import ChannelPreview from '@sendbird/uikit-react/ChannelList/components/ChannelPreview';
import { useChannelListContext } from '@sendbird/uikit-react/ChannelList/context';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { useSendbirdService } from 'services/SendbirdService';
import { useLoginService } from 'services/LoginService';
import path from 'routes/path';
import Tab from 'components/tabs/Tabs';
import { alpha, styled } from '@mui/material/styles';
import { Mui } from '@onedesign/ui';

const { Box, Divider, useTheme, CircularProgress, Badge } = Mui;

const ContainerStyles = styled(Box)(({ theme }) => ({
  boxShadow: `-20px 20px 40px -4px  ${alpha(theme.palette.grey[500], 0.24)}`,
}));

interface RenderChannelPreviewProps {
  channel: GroupChannel;
}

export interface ChannelListUIProps {
  renderChannelPreview?: (
    props: RenderChannelPreviewProps,
  ) => React.ReactElement;
  setCurrentChannelSelected?: (channel: GroupChannel) => void;
  currentChannelSelected?: GroupChannel;
  tab: string;
  setTab: (value: string) => void;
  tabs: { value: string; label: string }[];
}

const ChannelListUI: React.FC<ChannelListUIProps> = ({
  setCurrentChannelSelected,
  currentChannelSelected,
  tab,
  setTab,
  tabs,
}: ChannelListUIProps) => {
  const { allChannels, loading, initialized } = useChannelListContext();
  const state = useSendbirdStateContext();
  const config = state?.config;
  const { isOnline = false } = config;
  const { loginUser } = useLoginService();
  const { sendbirdAccessToken } = useSendbirdService();
  const limitedChannels = allChannels?.slice(0, 5) || [];
  const theme = useTheme();

  // 點查看全部
  const handleOpenSendbird = () => {
    window.open(`${path.SENDBIRD_CUSTOMER}`, '_blank')?.focus();
  };

  // 點特定對話匡=>channel資料塞到網址
  const handleOpenSelectedChannel = (channel: GroupChannel) => {
    const channelUrl = channel?.url;
    const queryString = `?channelUrl=${channelUrl}`;
    window.open(path.SENDBIRD_CUSTOMER + queryString, '_blank');
  };

  if (!loginUser?.staffNumber || !sendbirdAccessToken) return null;

  return (
    <ContainerStyles>
      {initialized && !loading && (
        <>
          <Box
            sx={{
              pl: '20px',
              pt: '10px',
              color: theme.palette.grey[800],
              fontWeight: 500,
            }}
          >
            聊天室
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: '10px',
            }}
          >
            <Badge
              // badgeContent={unreadCount}
              color="error"
              sx={{
                position: 'absolute',
                right: 280,
              }}
            />
            <Tab
              selectTab={tab}
              setSelectTab={value => setTab(value)}
              tabs={tabs}
            />
          </Box>
          <Divider />
          {tabs.length > 0 && limitedChannels.length > 0 && (
            <>
              <div className="sendbird-channel-list__body">
                <div>
                  {limitedChannels &&
                    limitedChannels.map((channel, idx) => {
                      const onClick = () => {
                        if (!isOnline) {
                          return;
                        }
                        setCurrentChannelSelected?.(channel);
                        handleOpenSelectedChannel(channel);
                      };

                      return (
                        <ChannelPreview
                          key={channel?.url}
                          tabIndex={idx}
                          onClick={onClick}
                          channel={channel}
                          isActive={
                            channel?.url === currentChannelSelected?.url
                          }
                          renderChannelAction={() => null}
                        />
                      );
                    })}
                </div>
              </div>
              <Box
                sx={{
                  color: theme.palette.primary.main,
                  ml: '124px',
                  mr: '124px',
                  mt: '14px',
                  mb: '14px',
                  fontWeight: 700,
                  cursor: 'pointer',
                }}
                onClick={handleOpenSendbird}
              >
                查看全部
              </Box>
            </>
          )}
        </>
      )}
      {loading && (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </ContainerStyles>
  );
};

export default ChannelListUI;
