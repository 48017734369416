// payment-flow
export enum EPaymentStatus {
  active = 'active',
  disable = 'disable',
}

export enum EPayment {
  newebpay = 'newebpay', // 藍星
  ecpay = 'ecpay', // 綠界
  chailease = 'chailease', // 中租
  farTrust = 'farTrust', // 遠信
  paynow = 'paynow',
  transfer = 'transfer', // 轉帳
  noCashTrade = 'noCashTrade', // 0元訂單
  payuni = 'payuni', // PAYUNi
}

export const PAYMENT_LABEL = {
  [EPayment.newebpay]: '藍星',
  [EPayment.ecpay]: '綠界',
  [EPayment.chailease]: '中租',
  [EPayment.farTrust]: '遠信',
  [EPayment.paynow]: 'PayNow',
  [EPayment.transfer]: '轉帳',
  [EPayment.noCashTrade]: '0元訂單',
  [EPayment.payuni]: 'PAYUNi 統一金流',
};

export type TInstallment = {
  installment: number;
  status: EPaymentStatus;
  payment?: EPayment;
};

export type TPayment = {
  installments?: TInstallment[] | undefined;
  status: EPaymentStatus;
};

export enum EPaymentFlow {
  credit = 'credit',
  noCard = 'noCard',
  farTrustNoCard = 'farTrustNoCard',
  noCashTrade = 'noCashTrade',
  transfer = 'transfer',
  costAveraging = 'costAveraging',
}

export type TPaymentFlow =
  | EPaymentFlow.credit
  | EPaymentFlow.noCard
  | EPaymentFlow.farTrustNoCard
  | EPaymentFlow.noCashTrade
  | EPaymentFlow.transfer
  | EPaymentFlow.costAveraging;

export type TPaymentSettingDto = {
  [key in TPaymentFlow]: TPayment;
};

export type TPaymentSettingResponseDto = TPaymentSettingDto & {
  id: string;
  createdAt: number;
};

export enum CONDITION_TYPE {
  IS_PAY_OFF = 'isPayOff',
  PRODUCTS_CONDITION_GROUP = 'productsConditionGroup',
  PLANS_CONDITION_GROUP = 'plansConditionGroup',
  STAFFS_CONDITION_GROUP = 'staffsConditionGroup',
}

export enum STRATEGY_TYPE {
  ACTIVITY = 'activity',
  MATCHING = 'matching',
  GIVEAWAY = 'giveaway',
  PRICE_DEDUCT = 'priceDeduct',
  PRICE_DISCOUNT = 'priceDiscount',
}

export interface IConditionEditData {
  type: CONDITION_TYPE;
  conditionData: string[];
}

export interface ISpuToSkuList {
  id: string;
  option: { label: string; value: string };
  price: number | string;
  quantity: number;
}

export interface IPromotionSku {
  quantity: number;
  sku: {
    id: string;
    name: string;
    price: number;
  };
}

export interface ISkuList {
  duration: number;
  skus: IPromotionSku[];
}

export interface IStrategyEditData {
  type: STRATEGY_TYPE;
  strategyData: {
    duration?: number;
    skus?: IPromotionSku[];
    discount?: number;
  };
}

export interface IStrategy {
  type: STRATEGY_TYPE;
  duration?: number;
  skus?: IPromotionSku[];
  discount?: number;
}

export interface IMailSettingData {
  address: string;
  activation: boolean;
  type: 'customer' | 'other';
}
export type TMailSettingKeyName =
  | 'officialContract'
  | 'previewContract'
  | 'collectionContract';

export type TMailSetting = {
  [key in TMailSettingKeyName]: IMailSettingData[];
};
