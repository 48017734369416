const checkCrmNo = (no: string) => {
  const reg =
    /https:\/\/oneclassvn.bitrix24.com\/crm\/deal\/details\/([0-9]{4,10})/;
  // const str = 'https://oneclass.bitrix24.com/crm/deal/details/35478/'
  return no.match(reg);
};

const createAudition = (
  name: string,
  value: string,
  teacherField: string,
  studentField: string,
  bookField: string,
  gradeField: string,
  crmDealData: Record<string, string>,
) => {
  return {
    name,
    value,
    teacherName: crmDealData[teacherField],
    studentName: crmDealData[studentField],
    bookIds: crmDealData[bookField],
    gradeFieldKey: gradeField,
    grade: crmDealData[gradeField],
  };
};

export { checkCrmNo, createAudition };
