import { useEffect } from 'react';
import { useLoginService } from 'services/LoginService';
import { Mui } from '@onedesign/ui';
import Router from 'routes/index';

const { Box } = Mui;

const Root = () => {
  const { isLoading, loginUser, setLoginUser, handleLoginPermission } =
    useLoginService();

  useEffect(() => {
    handleLoginPermission();
  }, []);
  return !isLoading ? (
    <Router loginUser={loginUser} setLoginUser={setLoginUser} />
  ) : (
    <Box />
  );
};

export default Root;
