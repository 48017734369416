import { Suspense, lazy, ElementType, useMemo } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import { LogoOnlyLayout, LoadingScreen, Image } from '@onedesign/ui';
// import { EnumHelper } from 'utils/enumHelper';
import { useRemoteConfigService } from 'services/RemoteConfigService';
import path, {
  Route,
  FirstRoute,
  SecondRoute,
  ThirdRoute,
  dataCentralRead,
  dataCentralSalesRead,
  dataCentralFinanceRead,
  customer,
  // customerDataRead,
  customerCreate,
  customerDetail,
  // customerDataEdit,
  // auditionPeriod,
  auditionRead,
  // auditionReservation,
  auditionVipPass,
  // auditionCourse,
  electroniccommerceCommodity,
  electroniccommerceSetting,
  electroniccommerceOrder,
  electroniccommerce,
  authorityEmployee,
  authorityRole,
  authorityDepartment,
  authority,
  report,
  reportDaily,
  reportRealtime,
  reportPerformanceRead,
  Auth,
  customerVisitor,
  visitor,
  // formalCourseRead,
  afterClassStudyRead,
  courseTagRead,
  courseFormalAuditionRead,
  courseCreate,
  courseEdit,
  courseRead,
  visiterDataRead,
  teacherListMaterialRead,
  teacherListRead,
  teacherMaterialRead,
  financialRead,
  teacherSalaryRead,
  salaryOverallRead,
  audition,
  badgeCentralRead,
  badgeEdit,
  badgeRead,
  textbookRead,
  textbookCreate,
  textbookReadInfo,
  auditionCourseRead,
  revenueRead,
  laborCostRead,
  tutorialCenterRead,
  tutorialCenterCourseRead,
  realtimeCourseRead,
  textbookEdit,
  courseReviewAllRead,
  courseReviewCounselorRead,
  courseReviewTeacherTrainingRead,
  courseReviewCourseSchedulingRead,
  courseReviewAuditRead,
  teacherContractRead,
  performanceExpenseRead,
  // teacherTalk,
  parentMessageRead,
  parentMessageEdit,
  parentMessageSwitch,
  ticketsRead,
  ticketsEdit,
  historyTicketsRead,
  historyTicketsExport,
} from 'routes/path';
// layouts
import { ReservationCourseProvider } from 'services/ReservationCourseService';
import {
  checkPermissionsInAuth,
  resolveAuthorizedPath,
} from 'utils/helpr/permission';
import DashboardLayout from '../layouts/dashboard';
import logoIcon from '@/favicon.svg';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

// Dashboard
const Login = Loadable(lazy(() => import('pages/Login')));
const Register = Loadable(lazy(() => import('pages/Register')));
const DataCenter = Loadable(lazy(() => import('pages/datacenter/DataCenter')));
const DataCenterSalesData = Loadable(
  lazy(() => import('pages/datacenter/salesdata/SalesData')),
);
const DataCenterFinancialData = Loadable(
  lazy(() => import('pages/datacenter/financialdata/FinancialData')),
);
const Customer = Loadable(lazy(() => import('pages/customer/Customer')));
const CustomerList = Loadable(
  lazy(() =>
    import('pages/customer/customerlist/CustomerList').then(
      ({ CustomerList }) => ({ default: CustomerList }),
    ),
  ),
);
const CustomerCreate = Loadable(
  lazy(
    () => import('pages/customer/customerlist/customercreate/CustomerCreate'),
  ),
);
const CustomerDetail = Loadable(
  lazy(
    () => import('pages/customer/customerlist/customerdetail/CustomerDetail'),
  ),
);
const CustomerEdit = Loadable(
  lazy(
    () =>
      import(
        'pages/customer/customerlist/customerdetail/customeredit/CustomerEdit'
      ),
  ),
);

const CustomerStudentCreate = Loadable(
  lazy(
    () =>
      import(
        'pages/customer/customerlist/customerdetail/studentcreate/StudentCreate'
      ),
  ),
);
const CustomerStudentRead = Loadable(
  lazy(
    () =>
      import(
        'pages/customer/customerlist/customerdetail/studentread/StudentRead'
      ),
  ),
);
const CustomerStudentEdit = Loadable(
  lazy(
    () =>
      import(
        'pages/customer/customerlist/customerdetail/studentedit/StudentEdit'
      ),
  ),
);
const CustomerCustomeSeriesForm = Loadable(
  lazy(
    () =>
      import(
        'pages/customer/customerlist/customerdetail/customseriesform/CustomSeriesForm'
      ),
  ),
);
const VisitorList = Loadable(
  lazy(() => import('pages/customer/visitorlist/VisitorList')),
);
const VisitorProcessed = Loadable(
  lazy(
    () =>
      import('pages/customer/visitorlist/visitorprocessed/VisitorProcessed'),
  ),
);
const Audition = Loadable(lazy(() => import('pages/audition/Audition')));
// const AuditionPeriod = Loadable(lazy(() => import('pages/audition/period/Period')));
const AuditionReservation = Loadable(
  lazy(() => import('pages/audition/reservation/Reservation')),
);
const AuditionVipPass = Loadable(
  lazy(() => import('pages/audition/vippass/VipPass')),
);
const AuditionCourse = Loadable(
  lazy(() => import('pages/audition/course/Course')),
);
const AuditionTrialCourse = Loadable(
  lazy(() => import('pages/audition/trialcourse/TrialCourse')),
);
const AuditionTrialCourseItem = Loadable(
  lazy(
    () => import('pages/audition/trialcourse/trialCourseForm/TrialCourseForm'),
  ),
);
const AuditionCourseItem = Loadable(
  lazy(() => import('pages/audition/course/courseForm/CourseForm')),
);
const AuditionPublicCourse = Loadable(
  lazy(() => import('pages/audition/publicCourse/PublicCourse')),
);
const AuditionPublicCourseItem = Loadable(
  lazy(
    () =>
      import('pages/audition/publicCourse/publicCourseForm/PublicCourseForm'),
  ),
);
const BatchScheduledCourse = Loadable(
  lazy(
    () => import('pages/audition/batchScheduledCourse/BatchScheduledCourse'),
  ),
);
const BatchScheduledCourseForm = Loadable(
  lazy(
    () => import('pages/audition/batchScheduledCourse/component/CourseForm'),
  ),
);
const BatchScheduledCourseDetail = Loadable(
  lazy(
    () => import('pages/audition/batchScheduledCourse/component/CourseDetail'),
  ),
);
const AuditionRealtimeCourse = Loadable(
  lazy(() => import('pages/audition/realtimecourse/RealtimeCourse')),
);

const AuditionCourseFeedback = Loadable(
  lazy(() => import('pages/audition/course/courseFeedback/CourseFeedback')),
);
const Coursetag = Loadable(
  lazy(() => import('pages/audition/coursetag/Coursetag')),
);
const ElectronicCommerce = Loadable(
  lazy(() => import('pages/electroniccommerce/ElectronicCommerce')),
);
const CommodityManage = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/Commodity')),
);
const Spu = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/spu/Spu')),
);
const SpuForm = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/spu/SpuForm')),
);
const Sku = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/sku/Sku')),
);
const SkuForm = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/sku/SkuForm')),
);
const Promotion = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/promotion/Promotion')),
);
const PromotionForm = Loadable(
  lazy(
    () => import('pages/electroniccommerce/commodity/promotion/PromotionForm'),
  ),
);
const Plan = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/plan/Plan')),
);
const PlanForm = Loadable(
  lazy(() => import('pages/electroniccommerce/commodity/plan/PlanForm')),
);
const Attributes = Loadable(
  lazy(
    () =>
      import('pages/electroniccommerce/commodity/sku/attributes/Attributes'),
  ),
);
const Setting = Loadable(
  lazy(() => import('pages/electroniccommerce/setting/Setting')),
);
const Mail = Loadable(
  lazy(() => import('pages/electroniccommerce/setting/mail/Mail')),
);
const Contract = Loadable(
  lazy(() => import('pages/electroniccommerce/setting/contract/Contract')),
);
const ExtensionContract = Loadable(
  lazy(
    () => import('pages/electroniccommerce/setting/extensionContract/Contract'),
  ),
);
const ExtensionContractForm = Loadable(
  lazy(
    () =>
      import('pages/electroniccommerce/setting/extensionContract/ContractForm'),
  ),
);
const ContractForm = Loadable(
  lazy(() => import('pages/electroniccommerce/setting/contract/ContractForm')),
);

const TeacherContractForm = Loadable(
  lazy(
    () =>
      import('pages/teachersmanagement/setting/components/TeacherContractForm'),
  ),
);

const TerminatedContractForm = Loadable(
  lazy(
    () =>
      import(
        'pages/teachersmanagement/setting/components/TerminatedContractForm'
      ),
  ),
);

const PaymentFlow = Loadable(
  lazy(
    () => import('pages/electroniccommerce/setting/paymentFlow/PaymentFlow'),
  ),
);
const Order = Loadable(
  lazy(() => import('pages/electroniccommerce/order/Order')),
);
const OrderList = Loadable(
  lazy(() => import('pages/electroniccommerce/order/OrderList')),
);
const OrderForm = Loadable(
  lazy(() => import('pages/electroniccommerce/order/OrderForm')),
);
const OrderFormTutorialCreate = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/ordertutorial/createorder/CreateOrder'
    ).then(({ CreateOrder }) => ({ default: CreateOrder })),
  ),
);
const OrderFormTutorialEdit = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/ordertutorial/editorder/EditOrder'
    ).then(({ EditOrder }) => ({ default: EditOrder })),
  ),
);
const OrderFormSchoolCreate = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/orderschool/createorder/CreateOrder'
    ).then(({ CreateOrder }) => ({ default: CreateOrder })),
  ),
);
const OrderFormSchoolEdit = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/orderschool/editorder/EditOrder'
    ).then(({ EditOrder }) => ({ default: EditOrder })),
  ),
);
const OrderFormLearningBarCreate = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/orderlearningbar/createorder/CreateOrder'
    ).then(({ CreateOrder }) => ({ default: CreateOrder })),
  ),
);
const OrderFormLearningBarEdit = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/orderlearningbar/editorder/EditOrder'
    ).then(({ EditOrder }) => ({ default: EditOrder })),
  ),
);

const OrderFormExtendedCreate = Loadable(
  lazy(() =>
    import(
      'pages/electroniccommerce/order/extended/createorder/CreateOrder'
    ).then(({ CreateOrder }) => ({ default: CreateOrder })),
  ),
);

const OrderFormExtendedEdit = Loadable(
  lazy(() =>
    import('pages/electroniccommerce/order/extended/editorder/EditOrder').then(
      ({ EditOrder }) => ({ default: EditOrder }),
    ),
  ),
);

const RefundAssistance = Loadable(
  lazy(
    () => import('pages/electroniccommerce/refundassistance/RefundAssistance'),
  ),
);
const RefundAssistanceForm = Loadable(
  lazy(
    () =>
      import('pages/electroniccommerce/refundassistance/RefundAssistanceForm'),
  ),
);
const TeachersManagement = Loadable(
  lazy(() => import('pages/teachersmanagement/TeachersManagement')),
);
const TeacherList = Loadable(
  lazy(() => import('pages/teachersmanagement/teachers/TeacherList')),
);
const TeacherForm = Loadable(
  lazy(() => import('pages/teachersmanagement/teachers/TeacherForm')),
);
const TeacherViewForm = Loadable(
  lazy(() => import('pages/teachersmanagement/teachers/TeacherViewForm/index')),
);
const TagList = Loadable(
  lazy(() => import('pages/teachersmanagement/teachers/TagList')),
);
const TeacherMaterial = Loadable(
  lazy(
    () => import('pages/teachersmanagement/teachermaterial/TeacherMaterial'),
  ),
);
const TeacherListInterview = Loadable(
  lazy(
    () =>
      import('pages/teachersmanagement/teacherinterview/TeacherInterviewList'),
  ),
);
const TeacherUnverifiedList = Loadable(
  lazy(
    () =>
      import('pages/teachersmanagement/teacherUnverified/TeacherInterviewList'),
  ),
);
const ContractSetting = Loadable(
  lazy(() => import('pages/teachersmanagement/setting/components/Setting')),
);
const SeriesDetail = Loadable(
  lazy(
    () =>
      import('pages/teachersmanagement/teachermaterial/series/SeriesDetail'),
  ),
);
const SeriesForm = Loadable(
  lazy(
    () => import('pages/teachersmanagement/teachermaterial/series/SeriesForm'),
  ),
);
const ReferrersList = Loadable(
  lazy(() => import('pages/teachersmanagement/referrers/ReferrerList')),
);

const ApplicantForm = Loadable(
  lazy(
    () => import('pages/teachersmanagement/teacherinterview/applicantDetaild'),
  ),
);
const ApplicantUnverifiedForm = Loadable(
  lazy(
    () => import('pages/teachersmanagement/teacherUnverified/applicantDetaild'),
  ),
);
const ResourceForm = Loadable(
  lazy(
    () =>
      import('pages/teachersmanagement/teachermaterial/resource/ResourceForm'),
  ),
);
const ExamForm = Loadable(
  lazy(() => import('pages/teachersmanagement/teachermaterial/exam/ExamForm')),
);
const HomeworkForm = Loadable(
  lazy(
    () =>
      import('pages/teachersmanagement/teachermaterial/homework/HomeworkForm'),
  ),
);
const OmsCourse = Loadable(
  lazy(() => import('pages/audition/omsCourse/OmsCourse')),
);

const CourseReview = Loadable(
  lazy(() => import('pages/audition/courseReview/CourseReview')),
);
const ReviewForm = Loadable(
  lazy(() => import('pages/audition/courseReview/ReviewForm/ReviewForm')),
);
const Authority = Loadable(lazy(() => import('pages/authority/Authority')));
const AuthorityEmployee = Loadable(
  lazy(() => import('pages/authority/employee/Employee')),
);
const AuthorityRole = Loadable(lazy(() => import('pages/authority/role/Role')));
const AuthorityDepartment = Loadable(
  lazy(() => import('pages/authority/department/Department')),
);
const Report = Loadable(lazy(() => import('pages/report/Report')));
const ReportDaily = Loadable(lazy(() => import('pages/report/daily/Daily')));
const ReportRealTime = Loadable(
  lazy(() => import('pages/report/realtime/RealTime')),
);
const ReportPerformance = Loadable(
  lazy(() =>
    import('pages/report/performance/Performance').then(({ Performance }) => ({
      default: Performance,
    })),
  ),
);
const ReportPerformanceDetail = Loadable(
  lazy(() =>
    import('pages/report/performance/performancedetail/PerformanceDetail').then(
      ({ PerformanceDetail }) => ({ default: PerformanceDetail }),
    ),
  ),
);

const CustomerTicket = Loadable(
  lazy(() => import('pages/customerticket/index')),
);

const InstantCase = Loadable(
  lazy(() => import('pages/customerticket/instantcase/InstantCase')),
);

const MyCase = Loadable(
  lazy(() => import('pages/customerticket/mycase/MyCase')),
);

const HistoryCase = Loadable(
  lazy(() => import('pages/customerticket/historycase/HistoryCase')),
);

const NotificationCenter = Loadable(
  lazy(
    () => import('pages/customerticket/notificationcenter/NotificationCenter'),
  ),
);

const CustomerChannelManagement = Loadable(
  lazy(
    () =>
      import(
        'pages/customerticket/customerchannelmanagement/CustomerChannelManagement'
      ),
  ),
);

const BadgeCentral = Loadable(
  lazy(() => import('pages/badgecentral/BadgeCentral')),
);

const RewardShipment = Loadable(
  lazy(
    () =>
      import(
        'pages/badgecentral/rewardshipmentmanagement/RewardShipmentManagement'
      ),
  ),
);
const RewardShipmentForm = Loadable(
  lazy(
    () =>
      import(
        'pages/badgecentral/rewardshipmentmanagement/RewardShipmentForm/RewardShipmentForm'
      ),
  ),
);
const Textbook = Loadable(
  lazy(() => import('pages/badgecentral/textbook/Textbook')),
);
const TextBookForm = Loadable(
  lazy(() => import('pages/badgecentral/textbook/textbookForm/TextbookForm')),
);
const Financial = Loadable(lazy(() => import('pages/financial/Financial')));
const Salary = Loadable(lazy(() => import('pages/financial/salary/Salary')));
const SalaryDetail = Loadable(
  lazy(() => import('pages/financial/salarydetail/SalaryDetail')),
);
const SalaryOverview = Loadable(
  lazy(() => import('pages/financial/salaryoverview/SalaryOverview')),
);
const UnearnedRevenue = Loadable(
  lazy(() => import('pages/financial/unearnedrevenue/UnearnedRevenue')),
);
const UnearnedRevenueDetail = Loadable(
  lazy(
    () => import('pages/financial/unearnedrevenuedetail/UnearnedRevenueDetail'),
  ),
);
const TutorialCenter = Loadable(
  lazy(() => import('pages/tutorialCenter/TutorialCenter')),
);
const TutorialCenterFormalCourse = Loadable(
  lazy(() => import('pages/tutorialCenter/formalCourse/FormalCourse')),
);
const TutorialCenterCourseItem = Loadable(
  lazy(() => import('pages/tutorialCenter/formalCourse/courseForm/CourseForm')),
);

const TutorialCenterCourseItemOneLinkPlusCourseForm = Loadable(
  lazy(
    () =>
      import(
        'pages/tutorialCenter/formalCourse/oneLinkPlusCourseForm/OneLinkPlusCourseForm'
      ),
  ),
);

const Advertisement = Loadable(lazy(() => import('pages/advertisement/index')));

const LiveApp = Loadable(
  lazy(() => import('pages/advertisement/advertisementLiveApp/LiveApp')),
);

const LiveAppForm = Loadable(
  lazy(
    () =>
      import(
        'pages/advertisement/advertisementLiveApp/liveAppForm/LiveAppForm'
      ),
  ),
);

const LaborCost = Loadable(
  lazy(() => import('pages/financial/laborcostlist/LaborCost')),
);
const PerformanceExpense = Loadable(
  lazy(() => import('pages/financial/performanceExpense/PerformanceExpense')),
);
const PermissionAbnormal = Loadable(
  lazy(() => import('pages/PermissionAbnormal')),
);
const NotFound = Loadable(lazy(() => import('pages/Page404')));
const ServerError = Loadable(lazy(() => import('pages/Server500')));

// const SendbirdTeacher = Loadable(
//   lazy(() => import('components/sendbirdTeacher/SendbirdKit')),
// );

const SendbirdCustomer = Loadable(
  lazy(
    () =>
      import('pages/customerticket/customerchannelmanagement/SendbirdCustomer'),
  ),
);

const SendbirdCustomerDetail = Loadable(
  lazy(
    () =>
      import(
        'pages/customerticket/customerchannelmanagement/customerchanneldetail/CustomerChannelDetail'
      ),
  ),
);

const OneAnswer = Loadable(lazy(() => import('pages/oneAnswer/OneAnswer')));
const OneAnswerList = Loadable(
  lazy(() => import('pages/oneAnswer/oneAnswerList/OneAnswerList')),
);

type TProps = {
  loginUser: any;
  setLoginUser: (arg: any) => void;
};

type Props = {
  path?: string;
  element?: JSX.Element;
  index?: boolean;
  children?: Props[];
};

// const authorizations = EnumHelper.getNames(Auth);
const Routes = ({ loginUser, setLoginUser }: TProps) => {
  const { parameter } = useRemoteConfigService();
  const permissions = useMemo(() => loginUser?.permissions || [], [loginUser]);
  const includeAuth = checkPermissionsInAuth(permissions);

  const { pathname } = useLocation();

  const routeConfig = [
    {
      path: Route.root,
      element: (
        <DashboardLayout loginUser={loginUser} setLoginUser={setLoginUser} />
      ),
      children: useMemo(() => {
        const rootFolder = [
          {
            element: (
              <Navigate
                to={(() => {
                  if (!loginUser) {
                    return path.LOGIN;
                  }
                  return resolveAuthorizedPath(permissions);
                })()}
              />
            ),
            index: true,
          },
        ] as Props[];
        includeAuth(dataCentralRead) &&
          rootFolder.push({
            path: Route.datacentral,
            element: <DataCenter />,
            children: (() => {
              const firstFolder = [
                {
                  element: (
                    <Navigate
                      to={(() => {
                        if (includeAuth(dataCentralSalesRead)) {
                          return path.DATA_CENTER_SALES_DATA;
                        }
                        if (includeAuth(dataCentralFinanceRead)) {
                          return path.DATA_CENTER_FINANCIAL_DATA;
                        }
                        return path.PERMISSION_ABNORMAL;
                      })()}
                    />
                  ),
                  index: true,
                },
              ] as Props[];

              includeAuth(dataCentralSalesRead) &&
                firstFolder.push({
                  path: FirstRoute.salesdata,
                  element: <DataCenterSalesData />,
                });
              includeAuth(dataCentralFinanceRead) &&
                firstFolder.push({
                  path: FirstRoute.financialdata,
                  element: <DataCenterFinancialData />,
                });
              return firstFolder;
            })(),
          });
        // 需有基本列表閱讀權限
        includeAuth(customerVisitor) &&
          rootFolder.push({
            path: Route.customer,
            element: <Customer />,
            children: (() => {
              const firstFolder = [
                {
                  element: (
                    <Navigate
                      to={(() => {
                        if (includeAuth(customer)) {
                          return path.CUSTOMER_LIST;
                        }
                        if (includeAuth(visitor)) {
                          return path.VISITOR_LIST;
                        }
                        return path.PERMISSION_ABNORMAL;
                      })()}
                    />
                  ),
                  index: true,
                },
              ] as Props[];

              // includeAuth(customerDataRead);
              includeAuth(customer) &&
                firstFolder.push({
                  path: FirstRoute.customerlist,
                  element: <CustomerList />,
                  children: (() => {
                    const secondFolder = [
                      {
                        element: (
                          <Navigate
                            to={(() => {
                              if (includeAuth(customerCreate)) {
                                return path.CUSTOMER_CREATE;
                              }
                              if (includeAuth(customer)) {
                                return path.CUSTOMER_DETAIL;
                              }
                              return path.PERMISSION_ABNORMAL;
                            })()}
                          />
                        ),
                        index: true,
                      },
                    ] as Props[];

                    includeAuth(customerCreate) &&
                      secondFolder.push({
                        path: SecondRoute.create,
                        element: <CustomerCreate />,
                      });

                    includeAuth(customerDetail) &&
                      secondFolder.push({
                        path: SecondRoute.customerdetail,
                        element: <CustomerDetail />,
                        children: (() => {
                          // TODO: thirdFolder 要加權限 * 4
                          const thirdFolder = [
                            // {
                            //   element: (
                            //     <Navigate
                            //       to={(() => {
                            //         if (includeAuth(customerEdit)) {
                            //           return path.CUSTOMER_EDIT;
                            //         }
                            //         return path.LOGIN;
                            //       })()}
                            //     />
                            //   ),
                            //   index: true,
                            // },
                          ] as Props[];

                          thirdFolder.push({
                            path: ThirdRoute.edit,
                            element: <CustomerEdit />,
                          });

                          thirdFolder.push({
                            path: ThirdRoute.studentcreate,
                            element: <CustomerStudentCreate />,
                          });

                          thirdFolder.push({
                            path: `${ThirdRoute.studentread}/:studentId`,
                            element: <CustomerStudentRead />,
                          });

                          thirdFolder.push({
                            path: `${ThirdRoute.studentedit}/:studentId`,
                            element: <CustomerStudentEdit />,
                          });

                          thirdFolder.push({
                            path: ThirdRoute.customseriescreate,
                            element: <CustomerCustomeSeriesForm />,
                          });

                          thirdFolder.push({
                            path: `${ThirdRoute.customseriesedit}/:seriesCode`,
                            element: <CustomerCustomeSeriesForm />,
                          });

                          return thirdFolder;
                        })(),
                      });
                    return secondFolder;
                  })(),
                });

              includeAuth(visiterDataRead) &&
                firstFolder.push({
                  path: FirstRoute.visitorlist,
                  element: <VisitorList />,
                  children: (() => {
                    const secondFolder: Props[] = [
                      // {
                      //   element: (
                      //     <Navigate
                      //       to={(() => {
                      //         // if (includeAuth(financialSalaryDetail)) {
                      //         return path.FINANCIAL_SALARY_DETAIL;
                      //         // }
                      //         return path.LOGIN;
                      //       })()}
                      //     />
                      //   ),
                      //   index: true,
                      // },
                    ];

                    includeAuth([Auth.VISITER_DATA_IMPORT]) &&
                      secondFolder.push({
                        path: SecondRoute.visitorprocessed,
                        element: <VisitorProcessed />,
                      });

                    return secondFolder;
                  })(),
                });

              return firstFolder;
            })(),
          });

        includeAuth(audition) &&
          rootFolder.push({
            path: Route.audition,
            element: <Audition />,
            children: (() => {
              const firstFolder = [
                {
                  element: (
                    <Navigate
                      to={(() => {
                        // if (includeAuth(auditionPeriod)) {
                        //   return path.AUDITION_PERIOD;
                        // }
                        if (includeAuth(auditionRead)) {
                          return path.AUDITION_RESERVATION;
                        }
                        if (includeAuth(auditionVipPass)) {
                          return path.AUDITION_VIP_PASS;
                        }
                        if (includeAuth(courseFormalAuditionRead)) {
                          return path.AUDITION_COURSE;
                        }
                        if (includeAuth(afterClassStudyRead)) {
                          return path.AUDITION_PUBLIC_COURSE;
                        }
                        if (includeAuth(realtimeCourseRead)) {
                          return path.AUDITION_REALTIME_COURSE;
                        }

                        if (includeAuth(courseTagRead)) {
                          return path.AUDITION_COURSETAG;
                        }
                        if (
                          includeAuth(courseReviewAllRead) ||
                          includeAuth(courseReviewCounselorRead) ||
                          includeAuth(courseReviewTeacherTrainingRead) ||
                          includeAuth(courseReviewCourseSchedulingRead) ||
                          includeAuth(courseReviewAuditRead)
                        ) {
                          return path.AUDITION_COURSE_REVIEW;
                        }
                        return path.PERMISSION_ABNORMAL;
                      })()}
                    />
                  ),
                  index: true,
                },
              ] as Props[];

              // includeAuth(auditionPeriod) &&
              //   firstFolder.push({
              //     path: FirstRoute.period,
              //     element: <AuditionPeriod />,
              //   });

              includeAuth(auditionRead) &&
                firstFolder.push({
                  path: FirstRoute.reservation,
                  element: (
                    <ReservationCourseProvider>
                      <AuditionReservation />
                    </ReservationCourseProvider>
                  ),
                });
              includeAuth(auditionVipPass) &&
                firstFolder.push({
                  path: FirstRoute.vipPass,
                  element: <AuditionVipPass />,
                });

              includeAuth(auditionCourseRead) &&
                firstFolder.push({
                  path: FirstRoute.trialcourse,
                  element: <AuditionTrialCourse />,
                  children: (() => {
                    const secondFolder = [
                      {
                        element: (
                          <Navigate
                            to={(() => {
                              return path.AUDITION_TRIAL_COURSE_READ;
                            })()}
                          />
                        ),
                        index: true,
                      },
                    ] as Props[];

                    secondFolder.push({
                      path: SecondRoute.read,
                      children: [
                        {
                          element: <Navigate to={path.NOT_FOUND} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <AuditionTrialCourseItem />,
                        },
                      ],
                    });
                    return secondFolder;
                  })(),
                });
              // course routes must need read authorization
              includeAuth(courseFormalAuditionRead) &&
                firstFolder.push({
                  path: FirstRoute.course,
                  element: <AuditionCourse />,
                  children: (() => {
                    const secondFolder = [
                      {
                        element: (
                          <Navigate
                            to={(() => {
                              if (includeAuth(courseCreate)) {
                                return path.AUDITION_COURSE_CREATE;
                              }
                              if (includeAuth(courseEdit)) {
                                return path.AUDITION_COURSE_EDIT;
                              }
                              if (includeAuth(courseRead)) {
                                return path.AUDITION_COURSE_READ;
                              }
                              return path.LOGIN;
                            })()}
                          />
                        ),
                        index: true,
                      },
                    ] as Props[];

                    includeAuth(courseCreate) &&
                      secondFolder.push({
                        path: SecondRoute.create,
                        element: <AuditionCourseItem />,
                        index: true,
                      });

                    includeAuth(courseCreate) &&
                      secondFolder.push({
                        path: SecondRoute.copy,
                        children: [
                          {
                            element: <Navigate to={path.NOT_FOUND} />,
                            index: true,
                          },
                          {
                            path: Route.id,
                            element: <AuditionCourseItem />,
                          },
                        ],
                      });

                    includeAuth(courseRead) &&
                      secondFolder.push({
                        path: SecondRoute.read,
                        children: [
                          {
                            element: <Navigate to={path.NOT_FOUND} />,
                            index: true,
                          },
                          {
                            path: Route.id,
                            element: <AuditionCourseItem />,
                          },
                        ],
                      });
                    includeAuth(courseEdit) &&
                      secondFolder.push({
                        path: SecondRoute.edit,
                        children: [
                          {
                            element: <Navigate to={path.NOT_FOUND} />,
                            index: true,
                          },
                          {
                            path: Route.id,
                            element: <AuditionCourseItem />,
                          },
                        ],
                      });
                    secondFolder.push({
                      path: SecondRoute.feedback,
                      children: [
                        {
                          element: <Navigate to={path.NOT_FOUND} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <AuditionCourseFeedback />,
                        },
                      ],
                    });

                    return secondFolder;
                  })(),
                });

              includeAuth(afterClassStudyRead) &&
                firstFolder.push({
                  path: FirstRoute.publicCourse,
                  element: <AuditionPublicCourse />,
                  children: (() => {
                    const secondFolder = [
                      {
                        element: (
                          <Navigate
                            to={(() => {
                              if (
                                includeAuth([Auth.AFTER_CLASS_STUDY_CREATE])
                              ) {
                                return path.AUDITION_PUBLIC_COURSE_CREATE;
                              }
                              if (includeAuth([Auth.AFTER_CLASS_STUDY_EDIT])) {
                                return path.AUDITION_PUBLIC_COURSE_EDIT;
                              }
                              if (includeAuth([Auth.AFTER_CLASS_STUDY_READ])) {
                                return path.AUDITION_PUBLIC_COURSE_READ;
                              }
                              return path.LOGIN;
                            })()}
                          />
                        ),
                        index: true,
                      },
                    ] as Props[];

                    includeAuth([Auth.AFTER_CLASS_STUDY_CREATE]) &&
                      secondFolder.push({
                        path: SecondRoute.create,
                        element: <AuditionPublicCourseItem />,
                        index: true,
                      });
                    includeAuth([Auth.AFTER_CLASS_STUDY_READ]) &&
                      secondFolder.push({
                        path: SecondRoute.read,
                        element: <AuditionPublicCourseItem />,
                        index: true,
                      });
                    includeAuth([Auth.AFTER_CLASS_STUDY_EDIT]) &&
                      secondFolder.push({
                        path: SecondRoute.edit,
                        element: <AuditionPublicCourseItem />,
                        index: true,
                      });
                    return secondFolder;
                  })(),
                });

              // includeAuth(batchScheduledCoursesRead) &&
              firstFolder.push({
                path: FirstRoute.batchscheduledcourse,
                element: <BatchScheduledCourse />,
                children: (() => {
                  const secondFolder = [
                    {
                      element: (
                        <Navigate
                          to={(() => {
                            if (
                              includeAuth([Auth.BATCH_SCHEDULED_COURSES_CREATE])
                            ) {
                              return path.AUDITION_BATCH_SCHEDULED_COURSE_CREATE;
                            }
                            return path.LOGIN;
                          })()}
                        />
                      ),
                      index: true,
                    },
                  ] as Props[];

                  includeAuth([Auth.BATCH_SCHEDULED_COURSES_CREATE]) &&
                    secondFolder.push({
                      path: SecondRoute.create,
                      element: <BatchScheduledCourseForm />,
                      index: true,
                    });

                  includeAuth([Auth.BATCH_SCHEDULED_COURSES_READ]) &&
                    secondFolder.push({
                      path: SecondRoute.read,
                      children: [
                        {
                          element: <Navigate to={path.NOT_FOUND} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <BatchScheduledCourseDetail />,
                        },
                      ],
                    });

                  return secondFolder;
                })(),
              });

              includeAuth(realtimeCourseRead) &&
                firstFolder.push({
                  path: FirstRoute.realtimecourse,
                  element: <AuditionRealtimeCourse />,
                  children: (() => {
                    const secondFolder = [
                      {
                        element: (
                          <Navigate
                            to={(() => {
                              if (includeAuth([Auth.COURSE_OBSERVATION_READ])) {
                                return path.AUDITION_REALTIME_COURSE_READ;
                              }
                              return path.LOGIN;
                            })()}
                          />
                        ),
                        index: true,
                      },
                    ] as Props[];

                    includeAuth(realtimeCourseRead) &&
                      secondFolder.push({
                        path: SecondRoute.read,
                        children: [
                          {
                            element: <Navigate to={path.NOT_FOUND} />,
                            index: true,
                          },
                          {
                            path: Route.id,
                            element: <TutorialCenterCourseItem />,
                          },
                        ],
                      });

                    return secondFolder;
                  })(),
                });
              firstFolder.push({
                path: FirstRoute.omscourse,
                element: <OmsCourse />,
              });
              includeAuth(courseTagRead) &&
                firstFolder.push({
                  path: FirstRoute.coursetag,
                  element: <Coursetag />,
                  children: (() => {
                    const secondFolder = [
                      {
                        element: (
                          <Navigate
                            to={(() => {
                              if (includeAuth(courseTagRead)) {
                                return path.AUDITION_COURSETAG;
                              }
                              return path.LOGIN;
                            })()}
                          />
                        ),
                        index: true,
                      },
                    ] as Props[];

                    return secondFolder;
                  })(),
                });

              (includeAuth(courseReviewAllRead) ||
                includeAuth(courseReviewCounselorRead) ||
                includeAuth(courseReviewTeacherTrainingRead) ||
                includeAuth(courseReviewCourseSchedulingRead) ||
                includeAuth(courseReviewAuditRead)) &&
                firstFolder.push({
                  path: FirstRoute.coursereview,
                  children: [
                    {
                      element: <CourseReview />,
                      index: true,
                    },
                  ],
                });

              firstFolder.push({
                path: FirstRoute.coursereview,
                element: <CourseReview />,
                children: (() => {
                  const secondFolder = [
                    {
                      element: (
                        <Navigate
                          to={(() => {
                            return path.AUDITION_COURSE_REVIEW_READ;
                          })()}
                        />
                      ),
                      index: true,
                    },
                  ] as Props[];

                  secondFolder.push({
                    path: SecondRoute.reviewdetail,
                    children: [
                      {
                        path: Route.id,
                        children: [
                          {
                            element: <Navigate to={path.NOT_FOUND} />,
                            index: true,
                          },
                          {
                            path: ':reviewId',
                            element: <ReviewForm />,
                          },
                        ],
                      },
                    ],
                  });
                  return secondFolder;
                })(),
              });
              return firstFolder;
            })(),
          });

        if (includeAuth(tutorialCenterRead)) {
          rootFolder.push({
            path: Route.tutorialcenter,
            element: <TutorialCenter />,
            children: (() => {
              const firstFolder = [] as Props[];
              if (includeAuth(tutorialCenterCourseRead)) {
                firstFolder.push({
                  path: FirstRoute.course,
                  element: <TutorialCenterFormalCourse />,
                  children: (() => {
                    const secondFolder = [] as Props[];

                    secondFolder.push({
                      path: SecondRoute.create,
                      element: <TutorialCenterCourseItem />,
                      index: true,
                    });

                    secondFolder.push({
                      path: SecondRoute.read,
                      children: [
                        {
                          element: <Navigate to={path.NOT_FOUND} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <TutorialCenterCourseItem />,
                        },
                      ],
                    });
                    secondFolder.push({
                      path: SecondRoute.copy,
                      children: [
                        {
                          element: <Navigate to={path.NOT_FOUND} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <TutorialCenterCourseItem />,
                        },
                      ],
                    });
                    secondFolder.push({
                      path: SecondRoute.edit,
                      children: [
                        {
                          element: <Navigate to={path.NOT_FOUND} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <TutorialCenterCourseItem />,
                        },
                      ],
                    });

                    // OneLink+
                    secondFolder.push({
                      path: SecondRoute.onelinkplus,
                      children: [
                        {
                          path: ThirdRoute.create,
                          element: (
                            <TutorialCenterCourseItemOneLinkPlusCourseForm />
                          ),
                          index: true,
                        },
                        {
                          path: ThirdRoute.read,
                          children: [
                            {
                              path: Route.id,
                              element: (
                                <TutorialCenterCourseItemOneLinkPlusCourseForm />
                              ),
                              index: true,
                            },
                          ],
                        },
                        {
                          path: ThirdRoute.edit,
                          children: [
                            {
                              path: Route.id,
                              element: (
                                <TutorialCenterCourseItemOneLinkPlusCourseForm />
                              ),
                              index: true,
                            },
                          ],
                        },
                        {
                          path: ThirdRoute.copy,
                          children: [
                            {
                              path: Route.id,
                              element: (
                                <TutorialCenterCourseItemOneLinkPlusCourseForm />
                              ),
                              index: true,
                            },
                          ],
                        },
                      ],
                    });

                    return secondFolder;
                  })(),
                });
              }
              return firstFolder;
            })(),
          });
        }

        // LiveApp廣告區
        if (parameter.ONECLUB_6204) {
          rootFolder.push({
            path: Route.advertisement,
            element: <Advertisement />,
            children: (() => {
              const firstFolder = [] as Props[];

              firstFolder.push({
                path: FirstRoute.liveapp,
                element: <LiveApp />,
                children: (() => {
                  const secondFolder = [] as Props[];

                  secondFolder.push({
                    path: SecondRoute.create,
                    element: <LiveAppForm />,
                    index: true,
                  });

                  secondFolder.push({
                    path: SecondRoute.edit,
                    children: [
                      {
                        element: <Navigate to={path.NOT_FOUND} />,
                        index: true,
                      },
                      {
                        path: Route.id,
                        element: <LiveAppForm />,
                      },
                    ],
                  });

                  return secondFolder;
                })(),
              });

              return firstFolder;
            })(),
          });
        }

        includeAuth(electroniccommerce) &&
          rootFolder.push({
            path: Route.electroniccommerce,
            element: <ElectronicCommerce />,
            children: (() => {
              const firstFolder = [
                {
                  element: (
                    <Navigate
                      to={(() => {
                        if (includeAuth(electroniccommerceCommodity)) {
                          return path.ELECTRONICCOMMERCE_COMMODITY;
                        }
                        if (includeAuth(electroniccommerceSetting)) {
                          return path.ELECTRONICCOMMERCE_SETTING;
                        }
                        if (includeAuth(electroniccommerceOrder)) {
                          return path.ELECTRONICCOMMERCE_ORDER;
                        }
                        return path.PERMISSION_ABNORMAL;
                      })()}
                    />
                  ),
                  index: true,
                },
              ] as Props[];
              includeAuth(electroniccommerceCommodity) &&
                firstFolder.push({
                  path: FirstRoute.commodity,
                  element: <CommodityManage />,
                  children: [
                    {
                      element: (
                        <Navigate to={path.ELECTRONICCOMMERCE_COMMODITY_SPU} />
                      ),
                      index: true,
                    },
                    {
                      path: SecondRoute.spu,
                      children: [
                        {
                          element: <Spu />,
                          index: true,
                        },
                        {
                          path: ThirdRoute.create,
                          element: <SpuForm />,
                        },
                        {
                          path: ThirdRoute.edit,
                          children: [
                            {
                              element: <Navigate to={Route.notFound} />,
                              index: true,
                            },
                            {
                              path: Route.id,
                              element: <SpuForm />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: SecondRoute.sku,
                      children: [
                        {
                          element: <Sku />,
                          index: true,
                        },
                        {
                          path: ThirdRoute.create,
                          element: <SkuForm />,
                        },
                        {
                          path: ThirdRoute.edit,
                          children: [
                            {
                              element: <Navigate to={Route.notFound} />,
                              index: true,
                            },
                            {
                              path: Route.id,
                              element: <SkuForm />,
                            },
                          ],
                        },
                        {
                          path: ThirdRoute.attribute,
                          element: <Attributes />,
                        },
                      ],
                    },
                    {
                      path: SecondRoute.plan,
                      children: [
                        {
                          element: <Plan />,
                          index: true,
                        },
                        {
                          path: ThirdRoute.create,
                          element: <PlanForm />,
                        },
                        {
                          path: ThirdRoute.edit,
                          children: [
                            {
                              element: <Navigate to={Route.notFound} />,
                              index: true,
                            },
                            {
                              path: Route.id,
                              element: <PlanForm />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: SecondRoute.promotion,
                      children: [
                        {
                          element: <Promotion />,
                          index: true,
                        },
                        {
                          path: ThirdRoute.create,
                          element: <PromotionForm />,
                        },
                        {
                          path: ThirdRoute.edit,
                          children: [
                            {
                              element: <Navigate to={Route.notFound} />,
                              index: true,
                            },
                            {
                              path: Route.id,
                              element: <PromotionForm />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                });

              includeAuth(electroniccommerceSetting) &&
                firstFolder.push({
                  path: FirstRoute.setting,
                  element: <Setting />,
                  children: [
                    {
                      element: (
                        <Navigate
                          to={path.ELECTRONICCOMMERCE_SETTING_PAYMENT}
                        />
                      ),
                      index: true,
                    },
                    {
                      path: SecondRoute.payment,
                      children: [
                        {
                          element: <PaymentFlow />,
                          index: true,
                        },
                      ],
                    },
                    {
                      path: SecondRoute.mail,
                      children: [
                        {
                          element: <Mail />,
                          index: true,
                        },
                      ],
                    },
                    {
                      path: SecondRoute.contract,
                      children: [
                        {
                          element: <Contract />,
                          index: true,
                        },
                        {
                          path: ThirdRoute.edit,
                          element: <ContractForm />,
                        },
                      ],
                    },
                    {
                      path: SecondRoute.extensioncontract,
                      children: [
                        {
                          element: <ExtensionContract />,
                          index: true,
                        },
                        {
                          path: ThirdRoute.edit,
                          element: <ExtensionContractForm />,
                        },
                      ],
                    },
                  ],
                });

              includeAuth(electroniccommerceOrder) &&
                firstFolder.push({
                  path: FirstRoute.order,
                  element: <Order />,
                  children: [
                    {
                      element: <OrderList />,
                      index: true,
                    },
                    {
                      path: SecondRoute.create,
                      element: <OrderForm />,
                    },
                    {
                      path: SecondRoute.edit,
                      children: [
                        {
                          element: <Navigate to={Route.notFound} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <OrderForm />,
                        },
                      ],
                    },
                    {
                      path: SecondRoute.copy,
                      children: [
                        {
                          element: <Navigate to={Route.notFound} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <OrderForm />,
                        },
                      ],
                    },
                    {
                      path: SecondRoute.ordertutorial,
                      children: [
                        {
                          path: ThirdRoute.create,
                          element: <OrderFormTutorialCreate />,
                          children: [
                            {
                              path: Route.id,
                              element: <OrderFormTutorialCreate />,
                            },
                          ],
                        },
                        {
                          path: ThirdRoute.edit,
                          children: [
                            {
                              path: Route.id,
                              element: <OrderFormTutorialEdit />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: SecondRoute.orderschool,
                      children: [
                        {
                          path: ThirdRoute.create,
                          element: <OrderFormSchoolCreate />,
                          children: [
                            {
                              path: Route.id,
                              element: <OrderFormSchoolCreate />,
                            },
                          ],
                        },
                        {
                          path: ThirdRoute.edit,
                          children: [
                            {
                              path: Route.id,
                              element: <OrderFormSchoolEdit />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: SecondRoute.orderlearningbar,
                      children: [
                        {
                          path: ThirdRoute.create,
                          element: <OrderFormLearningBarCreate />,
                          children: [
                            {
                              path: Route.id,
                              element: <OrderFormLearningBarCreate />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: SecondRoute.orderlearningbar,
                      children: [
                        {
                          path: ThirdRoute.edit,
                          children: [
                            {
                              path: Route.id,
                              element: <OrderFormLearningBarEdit />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: SecondRoute.orderextended,
                      children: [
                        {
                          path: ThirdRoute.create,
                          element: <OrderFormExtendedCreate />,
                          children: [
                            {
                              path: Route.id,
                              element: <OrderFormExtendedCreate />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: SecondRoute.orderextended,
                      children: [
                        {
                          path: ThirdRoute.edit,
                          children: [
                            {
                              path: Route.id,
                              element: <OrderFormExtendedEdit />,
                            },
                          ],
                        },
                      ],
                    },
                    // {
                    //   path: ThirdRoute.edit,
                    //   children: [
                    //     {
                    //       element: <Navigate to={Route.notFound} />,
                    //       index: true,
                    //     },
                    //     {
                    //       path: Route.id,
                    //       element: <PlanForm />,
                    //     },
                    //   ],
                    // },
                  ],
                });

              firstFolder.push({
                path: FirstRoute.refundAssistance,
                element: <RefundAssistance />,
                children: [
                  {
                    element: <RefundAssistanceForm />,
                    index: true,
                  },
                ],
              });

              includeAuth(authority) &&
                firstFolder.push({
                  path: Route.authority,
                });

              return firstFolder;
            })(),
          });

        includeAuth(badgeCentralRead) &&
          rootFolder.push({
            path: Route.badgecentral,
            element: <BadgeCentral />,
            children: (() => {
              const firstFolder = [
                {
                  element: (
                    // TODO要加權限
                    <Navigate
                      to={(() => {
                        if (includeAuth(badgeRead)) {
                          return path.MEDAL_CENTRAL_REWARD_REDEMPTION;
                        }
                        if (includeAuth(textbookRead)) {
                          return path.TEXTBOOK_MANAGEMENT;
                        }
                        return path.PERMISSION_ABNORMAL;
                      })()}
                    />
                  ),
                  index: true,
                },
              ] as Props[];

              includeAuth(badgeEdit) &&
                firstFolder.push({
                  path: FirstRoute.rewardshipmentmanagement,
                  element: <RewardShipment />,
                  children: [
                    {
                      path: SecondRoute.edit,
                      children: [
                        {
                          element: <Navigate to={Route.notFound} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <RewardShipmentForm />,
                        },
                      ],
                    },
                  ],
                });

              includeAuth(textbookRead) &&
                firstFolder.push({
                  path: FirstRoute.textbookmangement,
                  element: <Textbook />,
                  children: (() => {
                    const secondFolder = [
                      {
                        element: (
                          <Navigate
                            to={(() => {
                              if (includeAuth(textbookCreate)) {
                                return path.TEXTBOOK_CREATE;
                              }
                              if (includeAuth(textbookReadInfo)) {
                                return path.TEXTBOOK_READ;
                              }
                              if (includeAuth(textbookEdit)) {
                                return path.TEXTBOOK_EDIT;
                              }
                              return path.PERMISSION_ABNORMAL;
                            })()}
                          />
                        ),
                        index: true,
                      },
                    ] as Props[];

                    includeAuth(textbookCreate) &&
                      secondFolder.push({
                        path: SecondRoute.create,
                        element: <TextBookForm />,
                      });

                    includeAuth(textbookReadInfo) &&
                      secondFolder.push({
                        path: SecondRoute.read,
                        children: [
                          {
                            element: <Navigate to={path.NOT_FOUND} />,
                            index: true,
                          },
                          {
                            path: Route.id,
                            element: <TextBookForm />,
                          },
                        ],
                      });

                    includeAuth(textbookEdit) &&
                      secondFolder.push({
                        path: SecondRoute.edit,
                        children: [
                          {
                            element: <Navigate to={path.NOT_FOUND} />,
                            index: true,
                          },
                          {
                            path: Route.id,
                            element: <TextBookForm />,
                          },
                        ],
                      });
                    return secondFolder;
                  })(),
                });

              return firstFolder;
            })(),
          });

        includeAuth(teacherListMaterialRead) &&
          rootFolder.push({
            path: Route.teachersmanagement,
            element: <TeachersManagement />,
            children: (() => {
              const firstFolder = [
                {
                  element: (
                    <Navigate
                      to={(() => {
                        if (includeAuth(teacherListRead)) {
                          return path.TEACHER_MANAGEMENT_LIST;
                        }

                        if (includeAuth(teacherMaterialRead)) {
                          return path.TEACHER_MANAGEMENT_MATERIAL;
                        }

                        return path.PERMISSION_ABNORMAL;
                      })()}
                    />
                  ),
                  index: true,
                },
              ] as Props[];

              includeAuth(teacherListRead) &&
                firstFolder.push({
                  path: FirstRoute.teacherlist,
                  children: [
                    {
                      element: <TeacherList />,
                      index: true,
                    },
                    {
                      path: SecondRoute.create,
                      element: <TeacherForm />,
                    },
                    {
                      path: SecondRoute.view,
                      children: [
                        {
                          element: <Navigate to={Route.notFound} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <TeacherViewForm />,
                        },
                      ],
                    },
                    {
                      path: SecondRoute.edit,
                      children: [
                        {
                          element: <Navigate to={Route.notFound} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <TeacherForm />,
                        },
                      ],
                    },
                    {
                      path: SecondRoute.taglist,
                      element: <TagList />,
                    },
                  ],
                });

              includeAuth(teacherMaterialRead) &&
                firstFolder.push({
                  path: FirstRoute.teachermaterial,
                  children: [
                    {
                      element: <TeacherMaterial />,
                      index: true,
                    },
                    {
                      path: SecondRoute.seriescreate,
                      element: <SeriesForm />,
                    },
                    {
                      path: SecondRoute.seriesedit,
                      children: [
                        {
                          element: <Navigate to={Route.notFound} />,
                          index: true,
                        },
                        {
                          path: Route.code,
                          element: <SeriesForm />,
                        },
                      ],
                    },
                    {
                      path: SecondRoute.resourcecreate,
                      element: <ResourceForm />,
                    },
                    {
                      path: SecondRoute.resourceedit,
                      children: [
                        {
                          element: <Navigate to={Route.notFound} />,
                          index: true,
                        },
                        {
                          path: Route.code,
                          element: <ResourceForm />,
                        },
                      ],
                    },
                    {
                      path: SecondRoute.seriesread,
                      children: [
                        {
                          element: <Navigate to={Route.notFound} />,
                          index: true,
                        },
                        {
                          path: Route.code,
                          element: <SeriesDetail />,
                        },
                      ],
                    },
                    {
                      path: SecondRoute.examedit,
                      children: [
                        {
                          element: <Navigate to={Route.notFound} />,
                          index: true,
                        },
                        {
                          path: Route.code,
                          element: <ExamForm />,
                        },
                      ],
                    },
                    {
                      path: SecondRoute.homeworkedit,
                      children: [
                        {
                          element: <Navigate to={Route.notFound} />,
                          index: true,
                        },
                        {
                          path: Route.code,
                          element: <HomeworkForm />,
                        },
                      ],
                    },
                  ],
                });

              firstFolder.push({
                path: FirstRoute.teacherinterviewlist,
                children: [
                  {
                    element: <TeacherListInterview />,
                    index: true,
                  },
                  {
                    path: SecondRoute.applicantView,
                    children: [
                      {
                        element: <Navigate to={Route.notFound} />,
                        index: true,
                      },
                      {
                        path: Route.code,
                        element: <ApplicantForm />,
                      },
                    ],
                  },
                ],
              });

              firstFolder.push({
                path: FirstRoute.teacherunverifiedlist,
                children: [
                  {
                    element: <TeacherUnverifiedList />,
                    index: true,
                  },
                  {
                    path: SecondRoute.applicantView,
                    children: [
                      {
                        element: <Navigate to={Route.notFound} />,
                        index: true,
                      },
                      {
                        path: Route.code,
                        element: <ApplicantUnverifiedForm />,
                      },
                    ],
                  },
                ],
              });
              firstFolder.push({
                path: FirstRoute.referrersList,
                children: [
                  {
                    element: <ReferrersList />,
                    index: true,
                  },
                ],
              });
              if (includeAuth(teacherContractRead)) {
                firstFolder.push({
                  path: FirstRoute.contract,
                  children: (() => {
                    const secondFolder: Props[] = [];
                    secondFolder.push({
                      index: true,
                      element: <Navigate to={path.TEACHER_CONTRACT_SETTING} />,
                    });
                    secondFolder.push({
                      path: SecondRoute.teacherContract,
                      children: (() => {
                        const thirdFolder: Props[] = [];
                        thirdFolder.push({
                          index: true,
                          element: <ContractSetting />,
                        });
                        thirdFolder.push({
                          path: ThirdRoute.edit,
                          element: <TeacherContractForm />,
                        });
                        return thirdFolder;
                      })(),
                    });
                    secondFolder.push({
                      path: SecondRoute.terminatedContract,
                      children: (() => {
                        const thirdFolder: Props[] = [];
                        thirdFolder.push({
                          index: true,
                          element: <ContractSetting />,
                        });
                        thirdFolder.push({
                          path: ThirdRoute.edit,
                          element: <TerminatedContractForm />,
                        });
                        return thirdFolder;
                      })(),
                    });
                    return secondFolder;
                  })(),
                });
              }

              return firstFolder;
            })(),
          });

        includeAuth(authority) &&
          rootFolder.push({
            path: Route.authority,
            element: <Authority />,
            children: (() => {
              const firstFolder = [
                {
                  element: (
                    <Navigate
                      to={(() => {
                        if (includeAuth(authorityEmployee)) {
                          return path.AUTHORITY_EMPLOYEE;
                        }
                        if (includeAuth(authorityRole)) {
                          return path.AUTHORITY_ROLE;
                        }
                        if (includeAuth(authorityDepartment)) {
                          return path.AUTHORITY_DEPARTMENT;
                        }
                        return path.PERMISSION_ABNORMAL;
                      })()}
                    />
                  ),
                  index: true,
                },
              ] as Props[];

              includeAuth(authorityEmployee) &&
                firstFolder.push({
                  path: FirstRoute.employee,
                  element: <AuthorityEmployee />,
                });

              includeAuth(authorityRole) &&
                firstFolder.push({
                  path: FirstRoute.role,
                  element: <AuthorityRole />,
                });

              includeAuth(authorityDepartment) &&
                firstFolder.push({
                  path: FirstRoute.department,
                  element: <AuthorityDepartment />,
                });

              return firstFolder;
            })(),
          });

        includeAuth(report) &&
          rootFolder.push({
            path: Route.report,
            element: <Report />,
            children: (() => {
              const firstFolder = [
                {
                  element: (
                    <Navigate
                      to={(() => {
                        if (includeAuth(reportRealtime)) {
                          return path.REPORT_REALTIME;
                        }
                        if (includeAuth(reportDaily)) {
                          return path.REPORT_DAILY;
                        }
                        if (includeAuth(reportPerformanceRead)) {
                          return path.REPORT_PERFORMANCE;
                        }
                        return path.PERMISSION_ABNORMAL;
                      })()}
                    />
                  ),
                  index: true,
                },
              ] as Props[];

              includeAuth(reportDaily) &&
                firstFolder.push({
                  path: FirstRoute.daily,
                  element: <ReportDaily />,
                });
              includeAuth(reportRealtime) &&
                firstFolder.push({
                  path: FirstRoute.realtime,
                  element: <ReportRealTime />,
                });
              // TODO: need to add authorization
              includeAuth(reportPerformanceRead) &&
                firstFolder.push({
                  path: FirstRoute.performance,
                  element: <ReportPerformance />,
                  children: (() => {
                    const secondFolder: Props[] = [];
                    secondFolder.push({
                      path: SecondRoute.performancedetail,
                      element: <ReportPerformanceDetail />,
                      children: (() => {
                        const thirdFolder: Props[] = [];
                        thirdFolder.push({
                          path: ThirdRoute.edit,
                          element: <ReportPerformanceDetail />,
                        });
                        return thirdFolder;
                      })(),
                    });
                    return secondFolder;
                  })(),
                });

              return firstFolder;
            })(),
          });

        includeAuth(financialRead) &&
          rootFolder.push({
            path: Route.financial,
            element: <Financial />,
            children: (() => {
              const firstFolder = [
                {
                  element: (
                    <Navigate
                      to={(() => {
                        if (includeAuth(teacherSalaryRead)) {
                          return path.FINANCIAL_SALARY;
                        }
                        if (includeAuth(salaryOverallRead)) {
                          return path.FINANCIAL_SALARY_OVERVIEW;
                        }
                        if (includeAuth(revenueRead)) {
                          return path.FINANCIAL_UNEARNED_REVENUE;
                        }
                        if (includeAuth(laborCostRead)) {
                          return path.FINANCIAL_LABOR_COST_LIST;
                        }
                        // TODO: ONECLUB_6236 feature toggle
                        if (
                          parameter.ONECLUB_6236 &&
                          includeAuth(performanceExpenseRead)
                        ) {
                          return path.FINANCIAL_PERFORMANCE_EXPENSE_LIST;
                        }
                        return path.PERMISSION_ABNORMAL;
                      })()}
                    />
                  ),
                  index: true,
                },
              ] as Props[];

              includeAuth(teacherSalaryRead) &&
                firstFolder.push({
                  path: FirstRoute.salary,
                  element: <Salary />,
                  children: (() => {
                    const secondFolder: Props[] = [
                      {
                        element: (
                          <Navigate
                            to={(() => {
                              // if (includeAuth(financialSalaryDetail)) {
                              return path.FINANCIAL_SALARY_DETAIL;
                              // }
                              return path.LOGIN;
                            })()}
                          />
                        ),
                        index: true,
                      },
                    ];

                    secondFolder.push({
                      path: SecondRoute.salarydetail,
                      children: [
                        {
                          element: <Navigate to={path.NOT_FOUND} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <SalaryDetail />,
                        },
                      ],
                    });

                    return secondFolder;
                  })(),
                });
              includeAuth(salaryOverallRead) &&
                firstFolder.push({
                  path: FirstRoute.salaryoverview,
                  element: <SalaryOverview />,
                });

              includeAuth(revenueRead) &&
                firstFolder.push({
                  path: FirstRoute.unearnedrevenue,
                  element: <UnearnedRevenue />,
                  children: (() => {
                    const secondFolder: Props[] = [
                      {
                        element: (
                          <Navigate
                            to={(() => {
                              if (includeAuth(revenueRead)) {
                                return path.FINANCIAL_UNEARNED_REVENUE_DETAIL;
                              }
                              return path.LOGIN;
                            })()}
                          />
                        ),
                        index: true,
                      },
                    ];

                    secondFolder.push({
                      path: SecondRoute.unearnedrevenuedetail,
                      children: [
                        {
                          element: <Navigate to={path.NOT_FOUND} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <UnearnedRevenueDetail />,
                        },
                      ],
                    });
                    return secondFolder;
                  })(),
                });
              includeAuth(laborCostRead) &&
                firstFolder.push({
                  path: FirstRoute.laborcost,
                  element: <LaborCost />,
                });

              // TODO: ONECLUB_6236 feature toggle
              parameter.ONECLUB_6236 &&
                includeAuth(performanceExpenseRead) &&
                firstFolder.push({
                  path: FirstRoute.performanceexpense,
                  element: <PerformanceExpense />,
                });

              return firstFolder;
            })(),
          });

        // 會員案件管理，權限待todo
        parameter.ONECLUB_6599 &&
          rootFolder.push({
            path: Route.customerticket,
            element: <CustomerTicket />,
            children: (() => {
              const firstFolder = [
                {
                  element: (
                    <Navigate
                      to={(() => {
                        if (
                          includeAuth(ticketsRead) ||
                          includeAuth(ticketsEdit)
                        ) {
                          return path.CUSTOMER_TICKET_INSTANT_CASE;
                        }
                        // if (includeAuth(salaryOverallRead)) {
                        return path.CUSTOMER_TICKET_MY_CASE;
                        //
                        if (
                          includeAuth(historyTicketsRead) ||
                          includeAuth(historyTicketsExport)
                        ) {
                          return path.CUSTOMER_TICKET_HISTORY_CASE;
                        }
                        // if (includeAuth(revenueRead)) {
                        return path.CUSTOMER_TICKET_MEMBER_CHANNEL_MANAGEMENT;
                        // }

                        return path.PERMISSION_ABNORMAL;
                      })()}
                    />
                  ),
                  index: true,
                },
              ] as Props[];

              // includeAuth(salaryOverallRead) &&
              firstFolder.push({
                path: FirstRoute.instantcase,
                element: <InstantCase />,
              });

              firstFolder.push({
                path: FirstRoute.mycase,
                element: <MyCase />,
              });

              firstFolder.push({
                path: FirstRoute.historycase,
                element: <HistoryCase />,
              });

              firstFolder.push({
                path: FirstRoute.notificationcenter,
                element: <NotificationCenter />,
              });

              parameter.ONECLUB_7111 &&
                (includeAuth(parentMessageRead) ||
                  includeAuth(parentMessageEdit) ||
                  includeAuth(parentMessageSwitch)) &&
                firstFolder.push({
                  path: FirstRoute.memberchannelmanagement,
                  children: [
                    {
                      element: <CustomerChannelManagement />,
                      index: true,
                    },
                    {
                      path: SecondRoute.memberchanneldetail,
                      children: [
                        {
                          element: <Navigate to={Route.notFound} />,
                          index: true,
                        },
                        {
                          path: Route.id,
                          element: <SendbirdCustomerDetail />,
                        },
                      ],
                    },
                  ],
                });

              return firstFolder;
            })(),
          });

        parameter.ONE_ANSWER &&
          rootFolder.push({
            path: Route.oneAnswer,
            element: <OneAnswer />,
            children: (() => {
              const firstFolder = [
                {
                  element: (
                    <Navigate
                      to={(() => {
                        return path.ONE_ANSWER_RECORD;
                      })()}
                    />
                  ),
                  index: true,
                },
              ] as Props[];

              firstFolder.push({
                path: FirstRoute.record,
                element: <OneAnswerList />,
              });

              return firstFolder;
            })(),
          });

        return rootFolder;
      }, [permissions]),
    },
    {
      path: Route.login,
      element: <Login setLoginUser={setLoginUser} />,
    },
    {
      path: Route.register,
      element: <Register />,
    },
    {
      path: Route.all,
      element: (
        <LogoOnlyLayout
          logo={<Image src={logoIcon} sx={{ width: '50px' }} />}
        />
      ),
      children: [
        {
          path: Route.all,
          element: (
            <Navigate
              to={
                Object.values(path).includes(pathname)
                  ? path.LOGIN
                  : path.NOT_FOUND
              }
              replace
            />
          ),
        },
        {
          path: Route.permissionAbnormal,
          element: (
            <PermissionAbnormal
              permissions={permissions}
              setLoginUser={setLoginUser}
            />
          ),
        },
        { path: Route.serverError, element: <ServerError /> },
        { path: Route.notFound, element: <NotFound /> },
      ],
    },
  ];

  // if (includeAuth(teacherTalk)) {
  //   routeConfig.push({
  //     path: Route.sendbirdTeacher,
  //     element: <SendbirdTeacher />,
  //   });
  // }
  (includeAuth(parentMessageRead) ||
    includeAuth(parentMessageEdit) ||
    includeAuth(parentMessageSwitch)) &&
    routeConfig.push({
      path: Route.sendbirdcustomer,
      element: <SendbirdCustomer />,
    });

  return useRoutes(routeConfig);
};

export default Routes;
