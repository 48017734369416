import { TICKET_ROLE } from 'constants/customerTicket.constants';
import { TTL } from 'constants/common/common.constant';
import axios from 'plugins/api/axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getTicketsParams,
  getTicketsResponse,
  getQuestionTypesParams,
  getQuestionTypesResponse,
  createTicketsPayload,
  editTicketNamePayload,
  completeTicketPayload,
  transferTicketPayload,
  getMemberCustomersChannelsParams,
  assignTicketPayload,
} from '@type/sendbird';

const { sendbird, oneClassApiGateway } = axios;

export type TGetTicketsQuery<T> = {
  params: getTicketsParams;
  select: (data: getTicketsResponse) => T;
};

export type TGetQuestionTypes<T> = {
  params: getQuestionTypesParams;
  select: (data: getQuestionTypesResponse) => T;
};

export type TEditTicketNameParams = {
  payload: editTicketNamePayload;
  role: TICKET_ROLE;
  id: string;
};
export type TCompleteTickets = {
  role: TICKET_ROLE;
  id: string;
  payload: completeTicketPayload;
};

export type TTransferTickets = {
  role: TICKET_ROLE;
  id: string;
  payload: transferTicketPayload;
};

export type TAssignTickets = {
  role: TICKET_ROLE;
  id: string;
  payload: assignTicketPayload;
};

export const useGetChannelTypes = () => {
  return useQuery({
    queryKey: ['im', 'channel-types'],
    queryFn: async () => {
      const payload = {
        skip: 0,
        limit: 50,
      };
      return await sendbird.getChannelType(payload).then(response => {
        const { data, err } = response;
        if (err !== 0 || !data)
          return Promise.reject(new Error('取得頻道類型失敗'));
        return data || [];
      });
    },
  });
};

export const useGetIMUser = ({ staffNumber }: { staffNumber: string }) => {
  return useQuery({
    queryKey: ['im', 'users', staffNumber],
    enabled: !!staffNumber,
    staleTime: Infinity,
    retry: 1,
    queryFn: async () => {
      const payload = {
        oneClubId: staffNumber,
      };
      return await sendbird.getIMUser(payload).then(response => {
        const { data, err } = response;
        if (err !== 0 || !data) return null;
        return data;
      });
    },
  });
};

export const useSyncStaffChannels = () => {
  return useMutation({
    mutationFn: ({ oneClubId }: { oneClubId: string }) => {
      return sendbird.syncStaffChannels(oneClubId);
    },
    onSuccess: ({ err, msg }) => {
      if (err !== 0) Promise.reject(new Error(msg));
    },
  });
};

export const useSyncTeachersChannels = () => {
  return useMutation({
    mutationFn: ({ oneClubId }: { oneClubId: string }) => {
      return sendbird.syncTeachersChannels(oneClubId);
    },
    onSuccess: ({ err, msg }) => {
      if (err !== 0) Promise.reject(new Error(msg));
    },
  });
};

export const useGetTicketsQuery = <T>({
  params,
  select,
}: TGetTicketsQuery<T>) => {
  return useQuery({
    queryKey: ['im', 'tickets', params],
    select,
    refetchInterval: TTL.ONE_MINUTE,
    retry: 0,
    queryFn: async () => {
      return await sendbird.getTickets(params).then(response => {
        const { data, err } = response;
        if (err !== 0 || !data)
          return Promise.reject(new Error('取得任務單失敗'));
        return data || [];
      });
    },
  });
};

export const useGetTicketsList = (params: getTicketsParams) =>
  useGetTicketsQuery({
    params,
    select: ({ tickets }) => tickets,
  });

export const useGetTicketsTotal = (params: getTicketsParams) =>
  useGetTicketsQuery({
    params,
    select: ({ total }) => total,
  });

export const useGetTicketsByChannelUrl = (
  ticketId: string,
  role: TICKET_ROLE,
) => {
  return useQuery({
    queryKey: ['im', 'tickets', ticketId, role],
    enabled: !!ticketId && !!role,
    queryFn: async () => {
      return sendbird.getTicketsByChannelUrl(ticketId, role).then(response => {
        const { data, err } = response;
        if (err !== 0 || !data)
          return Promise.reject(new Error('取得單筆任務單失敗'));
        return data;
      });
    },
  });
};

export const useGetQuestionTypesQuery = <T>({
  params,
  select,
}: TGetQuestionTypes<T>) => {
  return useQuery({
    queryKey: ['im', 'question-types', params],
    select,
    queryFn: async () => {
      return await sendbird.getQuestionTypes(params).then(response => {
        const { data, err } = response;
        if (err !== 0 || !data)
          return Promise.reject(new Error('取得問題類別失敗'));
        return data || [];
      });
    },
  });
};

export const useGetQuestionTypesList = (params: getQuestionTypesParams) =>
  useGetQuestionTypesQuery({
    params,
    select: ({ questionTypes }) => questionTypes,
  });

export const useCreateTickets = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: createTicketsPayload) => {
      return sendbird.createTickets(payload);
    },
    onSuccess: ({ err, msg }) => {
      if (err !== 0) Promise.reject(new Error(msg));

      queryClient.invalidateQueries({
        queryKey: ['im'],
      });
    },
  });
};

export const useEditTicketName = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ payload, role, id }: TEditTicketNameParams) => {
      return sendbird.editTicketName(payload, role, id);
    },
    onSuccess: ({ err, msg }) => {
      if (err !== 0) Promise.reject(new Error(msg));

      queryClient.invalidateQueries({
        queryKey: ['im'],
      });
    },
  });
};

export const useCompleteTickets = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ role, id, payload }: TCompleteTickets) => {
      return sendbird.completeTicket(role, id, payload);
    },
    onSuccess: ({ err, msg }) => {
      if (err !== 0) Promise.reject(new Error(msg));

      queryClient.invalidateQueries({
        queryKey: ['im'],
      });
    },
  });
};

export const useTransferTickets = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ role, id, payload }: TTransferTickets) => {
      return sendbird.transferTicket(role, id, payload);
    },
    onSuccess: ({ err, msg }) => {
      if (err !== 0) Promise.reject(new Error(msg));

      queryClient.invalidateQueries({
        queryKey: ['im'],
      });
    },
  });
};

export const useGetMemberCustomersChannels = (
  params: getMemberCustomersChannelsParams,
) => {
  return useQuery({
    queryKey: ['mms', 'member', params],
    retry: 1,
    queryFn: async () => {
      return await oneClassApiGateway
        .getMemberCustomersChannels(params)
        .then(response => {
          const { data, error } = response;
          if (error) {
            return Promise.reject(new Error('取得客戶頻道失敗'));
          }
          return data || [];
        });
    },
  });
};

export const usePatchAssignTicket = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ role, id, payload }: TAssignTickets) => {
      return sendbird.assignTicket(role, id, payload);
    },
    onSuccess: ({ err, msg }) => {
      if (err !== 0) Promise.reject(new Error(msg));

      queryClient.invalidateQueries({
        queryKey: ['im'],
      });
    },
  });
};

export const useGetNotifications = (
  params: {
    skip: number;
    limit: number;
  },
  isLogin: boolean,
) => {
  return useQuery({
    queryKey: ['im', 'notifications', params],
    enabled: isLogin,
    retry: 1,
    queryFn: async () => {
      return await sendbird.getNotifications(params).then(response => {
        const { data, err } = response;
        if (err !== 0 || !data)
          return Promise.reject(new Error('取得通知中心失敗'));
        return data || [];
      });
    },
  });
};

export const usePatchNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => {
      return sendbird.patchNotifications(id);
    },
    onSuccess: ({ err, msg }) => {
      if (err !== 0) Promise.reject(new Error(msg));

      queryClient.invalidateQueries({
        queryKey: ['im'],
      });
    },
  });
};
