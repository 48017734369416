import { TeacherType } from 'constants/teacherRecruitment';
import axiosOrigin, { AxiosInstance } from 'axios';
import {
  IPaginationParams,
  TRCApiResponse,
  TSBApiResponse,
} from 'src/@type/common';
import {
  TeacherRecruitmentDto,
  ISearchFieldReferrer,
  IReferrerListResponse,
  IExportReferrer,
} from '@type/teacherRecruitment';

export interface IScheduleParams {
  startTime: number;
  endTime: number;
}
export interface ICreateScheduleParams {
  startTime: number;
  endTime: number;
  userId: string;
}
export interface IDeleteScheduleParams {
  remarks: string;
}

export interface IBitrixParams {
  id: string;
}

export interface ITutorials {
  userId: string;
  name: string;
  education: string;
  subject: string;
  project: string;
  projectId: string;
  isForced: boolean;
  startTime: number;
  endTime: number;
  remarks: string;
  year: string;
  usagePeriodStatus: string;
  courseName: string;
  tutorialName: string;
  crmId: string;
  bookId: string;
  advisor: string;
  advisorId: string;
  audiovisualNumber: string;
  classType: string;
}
export interface TRContractEditor {
  name?: string;
  email?: string;
  staffNumber?: string;
}

export interface TeacherRecruitmentContract {
  id: string;
  name: string;
  description?: string;
  editor?: TRContractEditor;
  content: string;
  createdAt: string;
  updatedAt: string;
}

export interface TRContractVariable {
  id: string;
  name: string;
  htmlContent: string;
  placeHolderHtml: string;
  defaultValue: string;
}

export enum ContractType {
  EMPLOYMENT_CONTRACT = 'employment',
  TERMINATED_CONTRACT = 'terminated',
}

export interface IContractListQueryParams extends IPaginationParams {
  contractType: ContractType;
}

export interface IGetContractQueryParams {
  contractType: ContractType;
}
export interface MigrateTeacherBody {
  oneClubId: string;
  firstName: string;
  lastName: string;
  nickName: string;
  gender: string;
  staffType: string;
  status: string;
  email: string;
  mobile?: string | null;
  mobileCountryCode?: string | null;
  telephone?: string | null;
  telephoneCountryCode?: string | null;
  teacherStaffID?: string | null;
  nationality: string;
  turnOfficialTime: string;
  isTurnOnCrossSubjectUI?: boolean;
  teacherType?: TeacherType;
}

export interface MigrateTeacherResponse {
  id: string;
  oneClubId: string;
  firstName: string;
  lastName: string;
  nickName: string;
  gender: string;
  staffType: string;
  status: string;
  email: string;
  mobile?: string | null;
  telephone?: string | null;
  teacherStaffID?: string | null;
  teacherType?: TeacherType;
}

export default (axios: AxiosInstance, teacher: string) => ({
  /**
   * 取得老師清單
   */
  getFileWithHeaders(url: string) {
    return axiosOrigin.get(`${teacher}/storage/file/${url}`);
  },
  getTeacherInterviewList(data: any): any {
    return axios.get(`${teacher}/applied-teacher-list`, { params: data });
  },

  getTeacherUnverifiedList(data: any): any {
    return axios.get(`${teacher}/unverified-teacher-list`, { params: data });
  },
  getDetailApplicant(id: string): any {
    return axios.get(`${teacher}/${id}`);
  },
  getFile(file: string): any {
    return axios.get(`${teacher}/storage/file/${file}`);
  },
  updateReview(id: string, payload: any): any {
    return axios.post(`${teacher}/update-interview-status/${id}`, payload);
  },
  update(id: string, payload: any): any {
    return axios.patch(`${teacher}/${id}`, payload);
  },
  promoteToOfficialTeacher(id: string): any {
    return axios.post(`${teacher}/set-official-teacher/${id}`);
  },

  getLatestContract(data: any): any {
    return axios.get(`${teacher}/contract/latest`, { params: data });
  },
  getListContract(data: any): any {
    return axios.get(`${teacher}/contract/list`, { params: data });
  },
  createNewContract(payload: any): any {
    return axios.post(`${teacher}/contract`, payload);
  },
  getContractVariables(data: any): TRCApiResponse<TRContractVariable[]> {
    return axios.get(`${teacher}/contract/variables`, { params: data });
  },
  getInfoByOneClubId(oneClubId: string): TRCApiResponse<TeacherRecruitmentDto> {
    return axios.get(`${teacher}/oneClubId/${oneClubId}`);
  },
  getReferrerList(
    data: ISearchFieldReferrer,
  ): TSBApiResponse<IReferrerListResponse> {
    return axios.get(`${teacher}/official-teachers-with-referral-code`, {
      params: data,
    });
  },
  exportRecommendationTeacherList(
    data: IExportReferrer,
  ): TSBApiResponse<string> {
    const params = new URLSearchParams({
      dateTo: data.dateTo,
      dateFrom: data.dateFrom,
    });

    return axios.get(
      `${teacher}/export-recommendation-teacher-list-data/?${params.toString()}`,
    );
  },
  migrateTeacher(
    data: MigrateTeacherBody,
  ): TRCApiResponse<MigrateTeacherResponse> {
    return axios.post(`${teacher}`, data);
  },
});
