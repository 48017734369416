export enum SENDBIRD_USER_TYPE {
  TEACHER = 'teacher',
  STAFF = 'staff',
}

export enum SENDBIRD_CHANNEL_TYPE {
  TEACHER_CHAT_ONE_TO_ONE = 'teacher_chat_one_to_one',
}

export enum SENDBIRD_TABS {
  MESSAGE_ANNOUNCEMENT = 'message:announcement',
  MESSAGE_ADMINISTRATION = 'message:administration',
  MESSAGE_TRAIN = 'message:train',
  MESSAGE_TASK = 'message:task',
  MESSAGE_COMMUNITY = 'message:community',
  MESSAGE_INDIVIDUAL = 'message:individual',
  MESSAGE_COMMUNICATION = 'message:communication',
  MESSAGE_TAKE_OFF = 'message:take_off',
  MESSAGE_HELP = 'message:help',
}
export const SENDBIRD_TABS_NAME = {
  [SENDBIRD_TABS.MESSAGE_ANNOUNCEMENT]: 'OneClass公告',
  [SENDBIRD_TABS.MESSAGE_ADMINISTRATION]: '行政事項',
  [SENDBIRD_TABS.MESSAGE_TRAIN]: '教師培訓',
  [SENDBIRD_TABS.MESSAGE_TASK]: '專案任務',
  [SENDBIRD_TABS.MESSAGE_COMMUNITY]: '團班邀約',
  [SENDBIRD_TABS.MESSAGE_INDIVIDUAL]: '課程邀課',
  [SENDBIRD_TABS.MESSAGE_COMMUNICATION]: '課程溝通',
  [SENDBIRD_TABS.MESSAGE_TAKE_OFF]: '請假',
  [SENDBIRD_TABS.MESSAGE_HELP]: '課程求救',
} as const;

export enum ETicketNotificationType {
  TICKETS_NEW = 'tickets_new',
  TICKETS_TRANSFER_MYSELF = 'tickets_transfer_myself',
  TICKETS_TRANSFER_DEPARTMENT = 'tickets_transfer_department',
  TICKETS_ASSIGN = 'tickets_assign',
}

export const ESendbirdNotificationTypeLabel = {
  [ETicketNotificationType.TICKETS_NEW]: '有新案件',
  [ETicketNotificationType.TICKETS_TRANSFER_MYSELF]: '案件被指派（自己）',
  [ETicketNotificationType.TICKETS_TRANSFER_DEPARTMENT]: '案件被指派（部門）',
  [ETicketNotificationType.TICKETS_ASSIGN]: '案件被他人承接',
};
