import {
  ICreateCourseParams,
  IEditCourseParams,
  IEditCourseStatusParams,
} from '@type/audition/course.type';
import { IPaginationParams } from '@type/common';
import { AxiosInstance } from 'axios';

export interface ICourseTagParams extends IPaginationParams {
  keywords?: string[];
  applicable?: string;
  courseType?: string;
  education?: string;
  subject?: string;
  isDeleted?: boolean;
}

export default (axios: AxiosInstance, course: string) => ({
  // attributes type
  /**
   * 新增課程
   */
  createCourse(data: ICreateCourseParams): any {
    return axios.post(`${course}/courses`, data);
  },
  /**
   * 取得課程列表
   */
  getCourseList(): any {
    return axios.get(`${course}/courses`);
  },
  /**
   * 編輯課程
   */
  editCourse(id: string, data: IEditCourseParams): any {
    return axios.patch(`${course}/courses/${id}`, data);
  },
  /**
   * 編輯課程狀態
   */
  editCourseStatus(id: string, data: IEditCourseStatusParams): any {
    return axios.patch(`${course}/courses/${id}/cancel`, data);
  },
  /**
   * 刪除課程
   */
  deleteCourse(id: string): any {
    return axios.delete(`${course}/courses/${id}`);
  },
  /**
   * 取得單筆課程
   */
  getCourse(id: string): any {
    return axios.get(`${course}/courses/${id}`);
  },
  /**
   * 下載上月報表
   */
  downloadReport(): any {
    return axios.get(`${course}/reports/download`, { responseType: 'blob' });
  },

  // course tag
  /**
   * 取得 tag
   */
  getCourseTag(type: string, data?: ICourseTagParams): any {
    return axios.get(`${course}/tag/${type}`, data && { params: data });
  },
  /**
   * 取得 叢書 tag
   */
  getSeriesTag(data: any): any {
    return axios.get(`${course}/tag/series`, { params: data });
  },
  /**
   * 取得 叢書教材 tag
   */
  getResourceTag(data: any): any {
    return axios.get(`${course}/tag/resource`, { params: data });
  },
  /**
   * 上傳 tag
   */
  async uploadCourseTag(type: string, file: File): Promise<any> {
    const form = new FormData();
    form.append('file', file);
    return axios.post(`${course}/tag/import/${type}`, form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
  /**
   * 刪除 tag (封存)
   */
  deleteTag(type: string, id: string): any {
    return axios.delete(`${course}/tag/${type}/${id}`);
  },
  /**
   * 取得教學風格 tag
   */
  getStyleTag(): any {
    return axios.get(`${course}/tag/style`);
  },
  /**
   * 取得篩選 tag
   */
  getTagMeta(): any {
    return axios.get(`${course}/tag/meta`);
  },

  /**
   * 回饋 feedback
   */
  getFeedback(id: string): any {
    return axios.get(`${course}/feedback/${id}`);
  },
  /**
   * 取得課程 修改紀錄列表。
   */
  getCourseRecords(id: string): any {
    return axios.get(`${course}/${id}/records`);
  },

  /**
   * bulk import courses
   */
  async uploadCourseExcel(file: File): Promise<any> {
    const form = new FormData();
    form.append('file', file);
    return axios.post(`${course}/courses/batchImportCourseExcel`, form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
});
