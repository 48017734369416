import { Typography } from '@mui/material';
import { Mui } from '@onedesign/ui';
import { Loader } from 'components/Loader';
import { format } from 'date-fns';
import { alpha } from '@mui/material/styles';
import path from 'routes/path';
import { ETicketNotificationType } from 'components/sendbird/sendbirdKit.constant';
import { notificationsItem } from '../../AccountPopover';

const { Box, useTheme, Divider } = Mui;

type TProps = {
  notificationList: notificationsItem[];
  isLoading: boolean;
  handleOpenInstantCase: (item: notificationsItem) => void;
};

const TicketNotify = ({
  notificationList,
  isLoading,
  handleOpenInstantCase,
}: TProps) => {
  const theme = useTheme();

  const handleOpenNotificationCenter = () => {
    window
      .open(`${path.CUSTOMER_TICKET_NOTIFICATION_CENTER}`, '_blank')
      ?.focus();
  };

  return (
    <Box
      sx={{
        width: '320px',
        height: '533px',
        color: theme.palette.grey[800],
        backgroundColor: 'white',
        boxShadow: `-20px 20px 40px -4px  ${alpha(
          theme.palette.grey[500],
          0.24,
        )}`,
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          py: '8px',
          px: '20px',
          height: '40px',
        }}
      >
        通知中心
      </Typography>
      <Divider />
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </Box>
      )}
      {!isLoading && (
        <Box sx={{ height: '440px' }}>
          {notificationList.length > 0 &&
            notificationList.map(item => {
              return (
                <>
                  <Box
                    key={item.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      py: '12px',
                      px: '16px',
                      height: '88px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleOpenInstantCase(item)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: '8px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: theme.palette.grey[800],
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '12px',
                          color: theme.palette.grey[500],
                        }}
                      >
                        {format(new Date(item.updatedAt), 'hh:mm a')}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '12px',
                        color: theme.palette.grey[500],
                        mb: '4px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {item.content}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alighItems: 'center',
                      }}
                    >
                      {item.type !== ETicketNotificationType.TICKETS_ASSIGN && (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '12px',
                            color: theme.palette.grey[500],
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            width: '280px',
                          }}
                        >
                          {item.name && item.name} ({item.oneClubId})
                        </Typography>
                      )}
                      {!item.isRead && (
                        <Box
                          sx={{
                            height: '12px',
                            width: '12px',
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: '50%',
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Divider />
                </>
              );
            })}
        </Box>
      )}
      {!isLoading && (
        <Box
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 700,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: '14px',
          }}
          onClick={handleOpenNotificationCenter}
        >
          查看全部
        </Box>
      )}
    </Box>
  );
};

export default TicketNotify;
