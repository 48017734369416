import { useMemo } from 'react';
import { Icon, SvgName } from 'components/Icon';
import path, {
  RouteName,
  FirstRouteName,
  // customerDataRead,
  // auditionPeriod,
  // auditionReservation,
  auditionRead,
  auditionVipPass,
  auditionCourseRead,
  audition,
  electroniccommerceCommodity,
  electroniccommerceSetting,
  electroniccommerceOrder,
  electroniccommerce,
  authorityEmployee,
  authorityRole,
  authorityDepartment,
  authority,
  report,
  reportDaily,
  reportRealtime,
  reportPerformanceRead,
  courseFormalAuditionRead,
  afterClassStudyRead,
  courseTagRead,
  customerVisitor,
  customerDataRead,
  visiterDataRead,
  teacherListMaterialRead,
  teacherListRead,
  teacherMaterialRead,
  financialRead,
  teacherSalaryRead,
  salaryOverallRead,
  badgeCentralRead,
  badgeRead,
  textbookRead,
  revenueRead,
  laborCostRead,
  dataCentralRead,
  dataCentralSalesRead,
  dataCentralFinanceRead,
  teacherApplicationRead,
  teacherApplicationReadUnverified,
  tutorialCenterRead,
  tutorialCenterCourseRead,
  realtimeCourseRead,
  courseReviewAllRead,
  courseReviewCounselorRead,
  courseReviewTeacherTrainingRead,
  courseReviewCourseSchedulingRead,
  courseReviewAuditRead,
  teacherContractRead,
  performanceExpenseRead,
  parentMessageRead,
  parentMessageEdit,
  parentMessageSwitch,
  ticketsRead,
  ticketsEdit,
  historyTicketsRead,
  historyTicketsExport,
} from 'routes/path';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { t } from 'utils/i18n';
import { useRemoteConfigService } from 'services/RemoteConfigService';
// ----------------------------------------------------------------------

const getIcon = (name: SvgName) => <Icon name={name} />;

const ICONS = {
  customer: getIcon(SvgName.Customer),
  points: getIcon(SvgName.Point),
  calendar: getIcon(SvgName.Calendar),
  introduction: getIcon(SvgName.Introduction),
  chartPie: getIcon(SvgName.ChartPie),
  userConfig: getIcon(SvgName.UserConfig),
  teacherManagement: getIcon(SvgName.Badge),
  financial: getIcon(SvgName.MoneyBill),
  badgeCentral: getIcon(SvgName.Pastafarianism),
  tutorialCenter: getIcon(SvgName.School),
  advertisement: getIcon(SvgName.Advertisement),
  customerTicket: getIcon(SvgName.CustomerTicket),
  oneAnswer: getIcon(SvgName.OneAnswer),
};

const NavConfig = (loginUser: any) => {
  const { parameter } = useRemoteConfigService();

  const nav = useMemo(() => {
    const permissions = loginUser?.permissions || [];

    const includeAuth = checkPermissionsInAuth(permissions);
    const newNav = [];
    includeAuth(dataCentralRead) &&
      newNav.push({
        title: t('routeName.datacenter', RouteName.datacentral),
        path: path.DATA_CENTRAL,
        icon: ICONS.chartPie,
        children: (() => {
          const newNav = [];
          includeAuth(dataCentralSalesRead) &&
            newNav.push({
              title: t('firstRouteName.salesdata', FirstRouteName.salesdata),
              path: path.DATA_CENTER_SALES_DATA,
            });
          includeAuth(dataCentralFinanceRead) &&
            newNav.push({
              title: t(
                'firstRouteName.financialdata',
                FirstRouteName.financialdata,
              ),
              path: path.DATA_CENTER_FINANCIAL_DATA,
            });
          return newNav;
        })(),
      });

    includeAuth(customerVisitor) &&
      newNav.push({
        title: t('routeName.customer', RouteName.customer),
        path: path.CUSTOMER,
        icon: ICONS.customer,
        children: (() => {
          const newNav = [];
          includeAuth(customerDataRead) &&
            newNav.push({
              title: t(
                'firstRouteName.customerlist',
                FirstRouteName.customerlist,
              ),
              path: path.CUSTOMER_LIST,
            });

          includeAuth(visiterDataRead) &&
            newNav.push({
              title: t(
                'firstRouteName.visitorlist',
                FirstRouteName.visitorlist,
              ),
              path: path.CUSTOMER_VISITOR_LIST,
            });
          return newNav;
        })(),
      });

    includeAuth(audition) &&
      newNav.push({
        title: t('routeName.audition', RouteName.audition),
        path: path.AUDITION,
        icon: ICONS.calendar,
        children: (() => {
          const newNav = [];

          // includeAuth(auditionPeriod) &&
          //   newNav.push({
          //     title: FirstRouteName.period,
          //     path: path.AUDITION_PERIOD,
          //   });

          includeAuth(auditionVipPass) &&
            newNav.push({
              title: t('firstRouteName.vipPass', FirstRouteName.vipPass),
              path: path.AUDITION_VIP_PASS,
            });

          includeAuth(auditionRead) &&
            newNav.push({
              title: t(
                'firstRouteName.reservation',
                FirstRouteName.reservation,
              ),
              path: path.AUDITION_RESERVATION,
            });
          includeAuth(auditionCourseRead) &&
            newNav.push({
              title: t(
                'firstRouteName.trialcourse',
                FirstRouteName.trialcourse,
              ),
              path: path.AUDITION_TRIAL_COURSE,
            });
          includeAuth(courseFormalAuditionRead) &&
            newNav.push({
              title: t('firstRouteName.course', FirstRouteName.course),
              path: path.AUDITION_COURSE,
            });
          includeAuth(afterClassStudyRead) &&
            newNav.push({
              title: t(
                'firstRouteName.publicCourse',
                FirstRouteName.publicCourse,
              ),
              path: path.AUDITION_PUBLIC_COURSE,
            });

          // TODO: feature toggle: ONECLUB_5214
          // parameter.ONECLUB_5214 &&
          // includeAuth(batchScheduledCoursesRead) &&
          newNav.push({
            title: t(
              'firstRouteName.batchscheduledcourse',
              FirstRouteName.batchscheduledcourse,
            ),
            path: path.AUDITION_BATCH_SCHEDULED_COURSE,
          });

          includeAuth(realtimeCourseRead) &&
            newNav.push({
              title: t(
                'firstRouteName.realtimeCourse',
                FirstRouteName.realtimecourse,
              ),
              path: path.AUDITION_REALTIME_COURSE,
            });

          newNav.push({
            title: t('firstRouteName.omsCourse', FirstRouteName.omscourse),
            path: path.AUDITION_OMS_COURSE,
          });

          includeAuth(courseTagRead) &&
            newNav.push({
              title: t('firstRouteName.coursetag', FirstRouteName.coursetag),
              path: path.AUDITION_COURSETAG,
            });
          // includeAuth(auditionCourseRead) &&
          //   newNav.push({
          //     title: FirstRouteName.course,
          //     path: path.AUDITION_COURSE,
          //   });

          // includeAuth(auditionCourseRead) &&
          //   newNav.push({
          //     title: FirstRouteName.publicCourse,
          //     path: path.AUDITION_PUBLIC_COURSE,
          //   });

          // includeAuth(auditionCourseRead) &&
          //   newNav.push({
          //     title: FirstRouteName.coursetag,
          //     path: path.AUDITION_COURSETAG,
          //   });

          (includeAuth(courseReviewAllRead) ||
            includeAuth(courseReviewCounselorRead) ||
            includeAuth(courseReviewTeacherTrainingRead) ||
            includeAuth(courseReviewCourseSchedulingRead) ||
            includeAuth(courseReviewAuditRead)) &&
            newNav.push({
              title: t(
                'firstRouteName.coursereview',
                FirstRouteName.coursereview,
              ),
              path: path.AUDITION_COURSE_REVIEW,
            });

          return newNav;
        })(),
      });

    if (includeAuth(tutorialCenterRead)) {
      newNav.push({
        title: t('routeName.tutorialcenter', RouteName.tutorialcenter),
        path: path.TUTORIAL_CENTER,
        icon: ICONS.tutorialCenter,
        children: (() => {
          const newNav = [];
          if (includeAuth(tutorialCenterCourseRead)) {
            newNav.push({
              title: t('firstRouteName.course', FirstRouteName.course),
              path: path.TUTORIAL_CENTER_FORMAL_COURSE,
            });
          }
          return newNav;
        })(),
      });
    }

    includeAuth(electroniccommerce) &&
      newNav.push({
        title: t('routeName.electroniccommerce', RouteName.electroniccommerce),
        path: path.ELECTRONICCOMMERCE,
        icon: ICONS.introduction,
        children: (() => {
          const newNav = [];

          includeAuth(electroniccommerceOrder) &&
            newNav.push({
              title: t('firstRouteName.order', FirstRouteName.order),
              path: path.ELECTRONICCOMMERCE_ORDER,
            });

          includeAuth(electroniccommerceCommodity) &&
            newNav.push({
              title: t('firstRouteName.commodity', FirstRouteName.commodity),
              path: path.ELECTRONICCOMMERCE_COMMODITY,
            });

          includeAuth(electroniccommerceSetting) &&
            newNav.push({
              title: t('firstRouteName.setting', FirstRouteName.setting),
              path: path.ELECTRONICCOMMERCE_SETTING,
            });

          newNav.push({
            title: t(
              'firstRouteName.refundAssistance',
              FirstRouteName.refundAssistance,
            ),
            path: path.ELECTRONICCOMMERCE_REFUND_ASSISTANCE,
          });

          return newNav;
        })(),
      });

    includeAuth(teacherListMaterialRead) &&
      newNav.push({
        title: t('routeName.teachersmanagement', RouteName.teachersmanagement),
        path: path.TEACHER_MANAGEMENT,
        icon: ICONS.teacherManagement,
        children: (() => {
          const newNav = [];
          includeAuth(teacherListRead) &&
            newNav.push({
              title: t(
                'firstRouteName.teacherlist',
                FirstRouteName.teacherlist,
              ),
              path: path.TEACHER_MANAGEMENT_LIST,
            });

          includeAuth(teacherApplicationRead) &&
            newNav.push({
              title: t(
                'firstRouteName.teacherinterviewlist',
                FirstRouteName.teacherinterviewlist,
              ),
              path: path.TEACHER_INTERVIEW_MANAGEMENT_LIST,
            });

          includeAuth(teacherApplicationReadUnverified) &&
            newNav.push({
              title: t(
                'firstRouteName.teacherunverifiedlist',
                FirstRouteName.teacherunverifiedlist,
              ),
              path: path.TEACHER_UNVERIFIED_MANAGEMENT_LIST,
            });
          includeAuth(teacherApplicationReadUnverified) &&
            newNav.push({
              title: t(
                'firstRouteName.referrersList',
                FirstRouteName.referrersList,
              ),
              path: path.REFERRERS_MANAGEMENT_LIST,
            });
          includeAuth(teacherMaterialRead) &&
            newNav.push({
              title: t(
                'firstRouteName.teachermaterial',
                FirstRouteName.teachermaterial,
              ),
              path: path.TEACHER_MANAGEMENT_MATERIAL,
            });

          includeAuth(teacherContractRead) &&
            newNav.push({
              title: t('firstRouteName.contract', FirstRouteName.contract),
              path: path.TEACHER_SETTING,
            });

          return newNav;
        })(),
      });

    includeAuth(report) &&
      newNav.push({
        title: t('routeName.report', RouteName.report),
        path: path.REPORT,
        icon: ICONS.chartPie,
        children: (() => {
          const newNav = [];
          includeAuth(reportRealtime) &&
            newNav.push({
              title: t('firstRouteName.realtime', FirstRouteName.realtime),
              path: path.REPORT_REALTIME,
            });

          includeAuth(reportDaily) &&
            newNav.push({
              title: t('firstRouteName.daily', FirstRouteName.daily),
              path: path.REPORT_DAILY,
            });
          includeAuth(reportPerformanceRead) &&
            newNav.push({
              title: t(
                'firstRouteName.performance',
                FirstRouteName.performance,
              ),
              path: path.REPORT_PERFORMANCE,
            });

          return newNav;
        })(),
      });

    includeAuth(financialRead) &&
      newNav.push({
        title: t('routeName.financial', RouteName.financial),
        path: path.FINANCIAL,
        icon: ICONS.financial,
        children: (() => {
          const newNav = [];

          includeAuth(teacherSalaryRead) &&
            newNav.push({
              title: t('firstRouteName.salary', FirstRouteName.salary),
              path: path.FINANCIAL_SALARY,
            });
          includeAuth(salaryOverallRead) &&
            newNav.push({
              title: t(
                'firstRouteName.salaryoverview',
                FirstRouteName.salaryoverview,
              ),
              path: path.FINANCIAL_SALARY_OVERVIEW,
            });
          includeAuth(revenueRead) &&
            newNav.push({
              title: t(
                'firstRouteName.unearnedrevenue',
                FirstRouteName.unearnedrevenue,
              ),
              path: path.FINANCIAL_UNEARNED_REVENUE,
            });

          includeAuth(laborCostRead) &&
            newNav.push({
              title: t('firstRouteName.laborcost', FirstRouteName.laborcost),
              path: path.FINANCIAL_LABOR_COST_LIST,
            });

          // TODO: ONECLUB_6236 feature toggle
          parameter.ONECLUB_6236 &&
            includeAuth(performanceExpenseRead) &&
            newNav.push({
              title: t(
                'firstRouteName.performanceexpense',
                FirstRouteName.performanceexpense,
              ),
              path: path.FINANCIAL_PERFORMANCE_EXPENSE_LIST,
            });

          return newNav;
        })(),
      });

    includeAuth(authority) &&
      newNav.push({
        title: t('routeName.authority', RouteName.authority),
        path: path.AUTHORITY,
        icon: ICONS.userConfig,
        children: (() => {
          const newNav = [];

          includeAuth(authorityEmployee) &&
            newNav.push({
              title: t('firstRouteName.employee', FirstRouteName.employee),
              path: path.AUTHORITY_EMPLOYEE,
            });

          includeAuth(authorityRole) &&
            newNav.push({
              title: t('firstRouteName.role', FirstRouteName.role),
              path: path.AUTHORITY_ROLE,
            });

          includeAuth(authorityDepartment) &&
            newNav.push({
              title: t('firstRouteName.department', FirstRouteName.department),
              path: path.AUTHORITY_DEPARTMENT,
            });

          return newNav;
        })(),
      });

    includeAuth(badgeCentralRead) &&
      newNav.push({
        title: t('routeName.badgecentral', RouteName.badgecentral),
        path: path.MEDAL_CENTRAL,
        icon: ICONS.badgeCentral,
        children: (() => {
          const newNav = [];

          includeAuth(badgeRead) &&
            newNav.push({
              title: t(
                'firstRouteName.rewardshipmentmanagement',
                FirstRouteName.rewardshipmentmanagement,
              ),
              path: path.MEDAL_CENTRAL_REWARD_REDEMPTION,
            });

          includeAuth(textbookRead) &&
            newNav.push({
              title: t(
                'firstRouteName.textbooklist',
                FirstRouteName.textbookmangement,
              ),
              path: path.TEXTBOOK_MANAGEMENT,
            });

          return newNav;
        })(),
      });

    parameter.ONECLUB_6204 &&
      newNav.push({
        title: RouteName.advertisement,
        path: path.ADVERTISEMENT,
        icon: ICONS.advertisement,
        children: (() => {
          const newNav = [];

          newNav.push({
            title: FirstRouteName.liveapp,
            path: path.ADVERTISEMENT_LIVE_APP,
          });

          return newNav;
        })(),
      });

    // 會員案件管理，權限todo
    parameter.ONECLUB_6599 &&
      newNav.push({
        title: RouteName.customerticket,
        path: path.CUSTOMER_TICKET,
        icon: ICONS.customerTicket,
        children: (() => {
          const newNav = [];

          (includeAuth(ticketsRead) || includeAuth(ticketsEdit)) &&
            newNav.push({
              title: FirstRouteName.instantcase,
              path: path.CUSTOMER_TICKET_INSTANT_CASE,
            });

          newNav.push({
            title: FirstRouteName.mycase,
            path: path.CUSTOMER_TICKET_MY_CASE,
          });

          (includeAuth(historyTicketsRead) ||
            includeAuth(historyTicketsExport)) &&
            newNav.push({
              title: FirstRouteName.historycase,
              path: path.CUSTOMER_TICKET_HISTORY_CASE,
            });

          parameter.ONECLUB_7111 &&
            (includeAuth(parentMessageRead) ||
              includeAuth(parentMessageEdit) ||
              includeAuth(parentMessageSwitch)) &&
            newNav.push({
              title: FirstRouteName.memberchannelmanagement,
              path: path.CUSTOMER_TICKET_MEMBER_CHANNEL_MANAGEMENT,
            });

          return newNav;
        })(),
      });

    parameter.ONE_ANSWER &&
      newNav.push({
        title: RouteName.oneanswer,
        path: path.ONE_ANSWER,
        icon: ICONS.oneAnswer,
        children: (() => {
          const newNav = [];

          newNav.push({
            title: FirstRouteName.record,
            path: path.ONE_ANSWER_RECORD,
          });

          return newNav;
        })(),
      });

    return newNav;
  }, [loginUser]);

  return [
    {
      subheader: `general v${import.meta.env.VITE_APP_VERSION || '1.0.0'}`, //  general v3.3.0
      items: nav,
    },
  ];
};

export default NavConfig;
