import { AxiosInstance } from 'axios';
import {
  IAudition,
  IContactDataResponse,
  ICRMDealResponse,
  IDealDataResponse,
  IGradeDataResponse,
  IUpdateCRMDealParams,
} from '@type/audition/vipPass.type';
import { TApiResponse, EApiStatus } from '@type/common';
import { EInboundWebhook } from '@type/bitrix';
import { createAudition } from 'utils/bitrix';
import { EDealBitrixCustomFields } from './../@type/bitrix';

export default (axios: AxiosInstance, bitrixHook: string) => ({
  // attributes type
  /**
   * 取得CRM資料
   */
  async getCRMDealData(bitrixId: string): TApiResponse<ICRMDealResponse> {
    const crmDealResponse = (await axios.get(
      `${bitrixHook}${EInboundWebhook.CRM_DEAL_GET}`,
      {
        params: { id: bitrixId },
      },
    )) as IDealDataResponse;
    const crmDealData = crmDealResponse?.result;
    if (crmDealData) {
      const contactResponse = (await axios.get(
        `${bitrixHook}${EInboundWebhook.CRM_CONTACT_GET}`,
        {
          params: { id: crmDealData.CONTACT_ID },
        },
      )) as IContactDataResponse;
      const contactData = contactResponse?.result;
      if (contactData) {
        const auditions: IAudition[] = [
          createAudition(
            'Buổi thử giọng 1',
            'firstAudition',
            EDealBitrixCustomFields.AUDITION_TEACHER_1,
            EDealBitrixCustomFields.AUDITION_STUDENT_NAME_1,
            EDealBitrixCustomFields.BOOK_ID_1,
            EDealBitrixCustomFields.STUDENT_1_GRADE,
            crmDealData,
          ),
          createAudition(
            'Buổi thử giọng 2',
            'secondAudition',
            EDealBitrixCustomFields.AUDITION_TEACHER_2,
            EDealBitrixCustomFields.AUDITION_STUDENT_NAME_2,
            EDealBitrixCustomFields.BOOK_ID_2,
            EDealBitrixCustomFields.STUDENT_2_GRADE,
            crmDealData,
          ),
          createAudition(
            'Buổi thử giọng 3',
            'thirdAudition',
            EDealBitrixCustomFields.AUDITION_TEACHER_3,
            EDealBitrixCustomFields.AUDITION_STUDENT_NAME_3,
            EDealBitrixCustomFields.BOOK_ID_3,
            EDealBitrixCustomFields.STUDENT_3_GRADE,
            crmDealData,
          ),
          createAudition(
            'Buổi thử giọng 4',
            'fourthAudition',
            EDealBitrixCustomFields.AUDITION_TEACHER_4,
            EDealBitrixCustomFields.AUDITION_STUDENT_NAME_4,
            EDealBitrixCustomFields.BOOK_ID_4,
            EDealBitrixCustomFields.STUDENT_4_GRADE,
            crmDealData,
          ),
        ];
        const gradesPromises = auditions.map(item =>
          axios.get(`${bitrixHook}${EInboundWebhook.CRM_DEAL_USERFIELD_LIST}`, {
            params: {
              [`FILTER[FIELD_NAME]`]: item.gradeFieldKey,
            },
          }),
        );
        const responses = (await Promise.all(
          gradesPromises,
        )) as unknown as IGradeDataResponse[];
        responses.forEach((response, responseIndex) => {
          const list = response.result?.[0]?.LIST;
          if (list) {
            auditions[responseIndex].grade =
              list.find(item => item.ID === auditions[responseIndex].grade)
                ?.VALUE || '';
          }
        });
        return Promise.resolve({
          status: EApiStatus.SUCCESS,
          data: {
            oneClubId: crmDealData[EDealBitrixCustomFields.TEACHING_SYSTEM_ID],
            phone: contactData.PHONE?.[0]?.VALUE || '',
            email: contactData.EMAIL?.[0]?.VALUE || '',
            auditions,
            lastName: contactData.LAST_NAME,
            name: contactData.NAME,
            address: contactData.ADDRESS,
          },
        });
      }
      return Promise.resolve({
        status: EApiStatus.FAILURE,
        data: {
          oneClubId: '',
          phone: '',
          auditions: [],
          email: '',
          address: '',
        },
      });
    } else {
      return Promise.resolve({
        status: EApiStatus.FAILURE,
        data: {
          oneClubId: '',
          phone: '',
          auditions: [],
          email: '',
          address: '',
        },
      });
    }
  },
  async updateCRMDealData(params: IUpdateCRMDealParams): TApiResponse<any> {
    const { dealId, fieldIdOneClubId, oneClubId } = params;
    const response = await axios.get(
      `${bitrixHook}${EInboundWebhook.CRM_DEAL_UPDATE}`,
      {
        params: {
          id: dealId,
          [`FIELDS[${fieldIdOneClubId}]`]: oneClubId,
        },
      },
    );
    return Promise.resolve({
      status: EApiStatus.SUCCESS,
      data: response,
    });
  },
});
